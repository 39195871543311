import { useState } from 'react';
import AuthenticationActions from '../../../actions/Authentication/AuthenticationActions';

import _BaseActions from '../../../actions/_BaseActions';

type LocalState_Type = {
    application_id?: string
    application_secret?: string
    password: string
    redirect_uri?: string
    state?: string
    username: string
}
export default function useOAuthLoginPage(application_id?: string, application_secret?: string, redirect_uri?: string, state?: string) {
    const [localState, setLocalState] = useState<LocalState_Type>({
        application_id,
        application_secret,
        password: '',
        redirect_uri,
        state,
        username: ''
    });

    const { getLabel } = _BaseActions();
    const { logInOAuth } = AuthenticationActions();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalState({ ...localState, [event.target.name]: event.target.value });
    };

    const onClickLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (localState.application_id && localState.application_secret && localState.redirect_uri && localState.state) {
            const application_token = await logInOAuth(localState.application_id, localState.application_secret, localState.redirect_uri, localState.username, localState.password);
            if (application_token) {
                const url = `${localState.redirect_uri}?token=state=${state}&access_token=${application_token.access_token}`;
                window.location.replace(url);
            }
        }
    };

    return {
        getLabel,
        localState,
        onChange,
        onClickLogin
    };
}

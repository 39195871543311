import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import AuthenticationActions from '../../../actions/Authentication/AuthenticationActions';


export default function useResetPasswordWithTokenPage(token: string) {
    const navigate = useNavigate();
    const [form, setForm] = useState({ username: '', password: '', confirmPassword: '' });
    const { changePasswordWithToken, changePasswordWithToken_ValidateToken } = AuthenticationActions();

    useEffect(() => {
        async function justAsync() {
            const isValid = await changePasswordWithToken_ValidateToken(token);
            if (!isValid)
                navigate('/UserHome');
        }

        justAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const onClickSend = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await changePasswordWithToken(form.username, form.password, form.confirmPassword, token);
    };

    return {
        form,
        onChange,
        onClickSend
    };
}
import { Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as Types from '../../Config/Types';

import _BaseActions from '../../actions/_BaseActions';
import AuthenticationActions from '../../actions/Authentication/AuthenticationActions';

type PropType = {
    onBrandClick: () => void
}

export default function Header({ onBrandClick }: PropType) {
    const authentication = useSelector((state: Types.InitialState_TYPE) => state.authentication);
    const configuration = useSelector((state: Types.InitialState_TYPE) => state.configuration);

    const { amIAuthenticated, azureAD_Enabled, logOut, selectAuthority, selectTenant } = AuthenticationActions();

    const { getLabel } = _BaseActions();
    const amILoggedIn = amIAuthenticated();
    const navigate = useNavigate();

    const openChangePasswordPage = () => {
        navigate('/ChangePassword');
    };

    return (
        <div className='main-header' id='header-section' style={{ position: 'static', border: 'none' }}>
            <ul className='header-list'>
                <li className='float-left header-style icon-menu icon list' id='hamburger' onClick={onBrandClick}>
                    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <g clipPath="url(#clip0)">
                            <path
                                d="M1.64328 19.1237C2.8468 15.5505 6.00992 13.9109 7.04886 13.3721C11.1506 11.2453 15.2627 11.8202 18.2663 12.2378C20.6554 12.5704 22.1495 12.7379 22.723 11.9748C23.047 11.5417 23.1293 10.7941 22.9776 9.81189C20.4908 9.18028 18.6444 6.85492 18.6444 4.08614C18.6444 2.49809 19.2513 1.05956 20.2362 0H4.36407C1.95445 0 0 1.95928 0 4.37488V23.5604C0.365173 23.2846 0.774063 23.068 1.21896 22.9314C1.10066 21.6089 1.23953 20.3173 1.64328 19.1237Z"
                                fill="white"
                            />
                            <path
                                d="M24.7751 13.5241C23.2629 15.5401 20.663 15.1766 17.9114 14.7925C14.9566 14.38 11.6057 13.9108 8.23172 15.6613C7.33421 16.1253 4.95288 17.3627 4.08109 19.9485C3.71335 21.0416 3.69792 22.1192 3.81878 23.0344C5.33605 23.635 6.41871 25.1561 6.41871 26.94C6.41871 27.6902 6.22584 28.394 5.89152 29.0024H23.6384C26.048 29.0024 28.0025 27.0432 28.0025 24.6276V8.6001C27.3133 9.20077 26.4826 9.63646 25.5723 9.8427C25.7317 11.3637 25.4694 12.5986 24.7751 13.5241Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="28" height="29" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </li>
                <li className='float-left header-style nav-pane' onClick={onBrandClick} style={{ cursor: 'pointer' }} ><b>flowbot</b></li>
                <li className='float-right header-style nav-pane header_user_icon'>
                    <NavDropdown
                        id="nav-dropdown"
                        title={
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path
                                    d="M18.0002 0.00164941C8.01695 -0.133191 -0.133191 8.01695 0.00164941 18.0002C0.134783 27.5747 7.93075 35.3707 17.5052 35.5038C27.4902 35.6404 35.6387 27.4902 35.5021 17.507C35.3707 7.93075 27.5747 0.134783 18.0002 0.00164941ZM28.7891 27.9297C28.7551 27.9665 28.7135 27.9953 28.6671 28.0142C28.6207 28.0331 28.5707 28.0415 28.5207 28.0389C28.4707 28.0363 28.4219 28.0227 28.3777 27.9991C28.3336 27.9755 28.2951 27.9425 28.2651 27.9024C27.5018 26.9037 26.567 26.0487 25.5043 25.3772C23.3315 23.9827 20.5784 23.2146 17.7527 23.2146C14.9271 23.2146 12.1739 23.9827 10.0011 25.3772C8.93853 26.0484 8.00371 26.9031 7.24034 27.9016C7.21035 27.9417 7.1719 27.9747 7.12773 27.9983C7.08356 28.0218 7.03474 28.0354 6.98474 28.038C6.93473 28.0406 6.88477 28.0322 6.83838 28.0133C6.792 27.9945 6.75033 27.9656 6.71634 27.9289C4.21213 25.2256 2.7936 21.6932 2.73259 18.0088C2.59348 9.70415 9.40802 2.75307 17.716 2.73259C26.0241 2.7121 32.7729 9.45837 32.7729 17.7527C32.7757 21.526 31.3528 25.1611 28.7891 27.9297Z"
                                    fill="white" />
                                <path
                                    d="M17.7527 8.19434C16.0697 8.19434 14.5481 8.82501 13.4668 9.97115C12.3855 11.1173 11.8453 12.7021 11.9673 14.4029C12.2148 17.7526 14.8101 20.4835 17.7527 20.4835C20.6952 20.4835 23.2854 17.7526 23.538 14.4038C23.6643 12.7192 23.1283 11.1489 22.0291 9.98139C20.9436 8.82928 19.4245 8.19434 17.7527 8.19434Z"
                                    fill="white" />
                            </svg>}>
                        {amILoggedIn ?
                            <NavDropdown.Item eventKey="4.1" onClick={() => logOut(undefined, '/', navigate)}>{getLabel('sideBar.header.logOut')}</NavDropdown.Item> : ''}
                        {amILoggedIn && configuration.authentication?.enableChangePassword && !azureAD_Enabled
                            ? <NavDropdown.Item eventKey="4.2" onClick={openChangePasswordPage}>{getLabel('sideBar.header.changePassword')}</NavDropdown.Item>
                            : ''}
                    </NavDropdown>
                </li>
                {amILoggedIn ?
                    <li className='float-right header-style nav-pane header_user_icon'>
                        <Nav.Link>{authentication.fullName ?? authentication.userName}</Nav.Link>
                    </li> : ''}
                {amILoggedIn && authentication.selectedAuthority?.name && authentication.authorityList ?
                    <li className='float-right header-style nav-pane header_user_icon'>
                        {authentication.authorityList.length === 1 ?
                            <NavDropdown disabled={true} id="dropdown-toggle" className="navbar-inverse" title={`${getLabel('toolbar.authority')}${authentication.selectedAuthority?.name}`} />
                            :
                            <NavDropdown id="nav-dropdown" className="navbar-inverse" title={`${getLabel('toolbar.authority')}${authentication.selectedAuthority?.name}`}>
                                {authentication.authorityList.filter(y => y.id !== authentication.selectedAuthority?.id).map((x, index) =>
                                    <NavDropdown.Item key={index} style={{ alignContent: 'left' }}
                                        eventKey={index} onClick={() => selectAuthority(x.id)}>
                                        {x.name}
                                    </NavDropdown.Item>
                                )}
                            </NavDropdown>}
                    </li> : ''}
                {amILoggedIn && authentication.selectedTenant?.name && authentication.tenantList ?
                    <li className='float-right header-style nav-pane header_user_icon'>
                        {authentication.tenantList.length === 1 ?
                            <NavDropdown disabled={true} id="dropdown-toggle" className="navbar-inverse" title={`${getLabel('toolbar.tenant')}${authentication.selectedTenant?.name}`} />
                            :
                            <NavDropdown id="nav-dropdown" className="navbar-inverse" title={`${getLabel('toolbar.tenant')}${authentication.selectedTenant?.name}`}>
                                {authentication.tenantList.filter(y => y.id !== authentication.selectedTenant?.id).map((x, index) =>
                                    <NavDropdown.Item key={index} style={{ alignContent: 'left' }}
                                        eventKey={index} onClick={() => selectTenant(x.id)}>
                                        {x.name}
                                    </NavDropdown.Item>
                                )}
                            </NavDropdown>}
                    </li> : ''}
            </ul>
        </div>
    );
}

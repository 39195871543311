import { Components } from '@firedesktop/react-base';
import React from 'react';
import _BaseActions from '../../../actions/_BaseActions';

import useResetPasswordRequestPage from './useResetPasswordRequestPage';

export default function ResetPasswordRequestPage() {
    const { form, onChange, onClickSend } = useResetPasswordRequestPage();
    const { getLabel } = _BaseActions();

    return (
        <React.Fragment>
            <div className="login_main">
                <div className="login_form_center">
                    <div className="login_box text-center">
                        <div className="login_logo">
                            <Components.AppIcon name={'logo-main'} className={'contract-login-svg'} />
                        </div>
                        <h3 className="fz36 mb-4">{getLabel('resetPasswordPage.title')}</h3>
                        <form>
                            <Components.AppInput
                                appIcon={<Components.AppIcon iconHeight={24} iconWidth={24} name={'user'} className={'tertiary-svg'} iconClassName='login_form_svg' />}
                                className='appInput_form_for_icon'
                                name='usernameOrMail'
                                onChange={onChange}
                                placeholder={getLabel('resetPasswordPage.usernameOrMail_placeholder')}
                                type='text'
                                value={form?.usernameOrMail}
                            />
                            <button className="login_btn mt-3" onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickSend(e)}>{getLabel('resetPasswordPage.send_button')}</button>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}
import { useState } from 'react';
import AuthenticationActions from '../../../actions/Authentication/AuthenticationActions';

function useChangePasswordPage(history: any) {
    const [form, setForm] = useState({ oldPassword: '', newPassword: '', renewPassword: '', conta: 0 });

    const { changePassword, logOut } = AuthenticationActions();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const onClickChangePassword = async (e: any) => {
        const res = await changePassword(form.oldPassword, form.newPassword, form.renewPassword);
        if (!res && form.conta++ > 2)
            await logOut(undefined, undefined, undefined);
    };

    return {
        form,
        onChange,
        onClickChangePassword
    };
}

export default useChangePasswordPage;

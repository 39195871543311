export default function CookiesActions() {

    const cookie_set = (value: string, daysToExpire: number) => {
        const date = new Date();
        date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000)); // Convert days to milliseconds
        const expires = 'expires=' + date.toUTCString();
        document.cookie = 'SessionToken=' + value + '; ' + expires + '; path=/';
    };

    const cookie_delete = () => {
        document.cookie = 'SessionToken=; max-age=0; path=/';
    };


    return {
        cookie_delete,
        cookie_set
    };
}
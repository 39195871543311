import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Components, Utils } from '@firedesktop/react-base';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import _BaseActions from '../_BaseActions';
import CookiesActions from '../Authentication/CookiesActions';

import * as Types from '../../Config/Types';


export default function AzureADActions(performeFDLogin: boolean = false) {
    const configuration = useSelector((state: Types.InitialState_TYPE) => state.configuration);
    const dispatch = useDispatch();

    const { accounts, instance } = useMsal();
    const azureAD_isAuthenticated = useIsAuthenticated();

    const { getLabel, lockScreenRequest, toast, unLockScreenRequest, updateAppState } = _BaseActions();
    const { cookie_set } = CookiesActions();
    const runner = new Utils.Fetch.FetchWrapper();

    const _LoginRequest = {
        scopes: ['User.Read']
    };

    let azureAD_Enabled = false;
    try {
        // @ts-ignore
        if (msalConfiguration)
            azureAD_Enabled = true;
    } catch (err) { }


    useEffect(() => {
        if (performeFDLogin) {
            // console.log(`AzureADActions - isAuthenticated: ${azureAD_isAuthenticated}`);
            if (azureAD_isAuthenticated) {
                instance.acquireTokenSilent({
                    ..._LoginRequest,
                    account: accounts[0]
                }).then((response) => {
                    fd_logIn(response.accessToken, response.account?.username ?? '');
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [azureAD_isAuthenticated]);

    const azureAD_Login = () => {
        instance.loginPopup(_LoginRequest).catch(error => {
            console.log('SignInButton - popup catch', error);
        });
    };

    const azureAD_LogOff = async () => {
        try {
            if (azureAD_isAuthenticated) {
                await instance.logoutPopup({
                    postLogoutRedirectUri: '/',
                    mainWindowRedirectUri: '/'
                });
            }
        } catch (err) { console.error('AzureAD LogOut', err); }
    };

    // **************************************************************************************************
    //              Private Functions
    // **************************************************************************************************
    const fd_logIn = async (azureADToken: string, userName: string) => {
        const url = `${configuration.api?.authentication}Authentication/LoginWithAzureAD`;

        dispatch(lockScreenRequest());

        return await runner.post(url, undefined, undefined, undefined, {
            id_Token: azureADToken,
            userName
        }).then((loginResponse: Types.AuthenticationState_TYPE) => {
            if (configuration.authentication?.localStorageName)
                localStorage.setItem(configuration.authentication.localStorageName, JSON.stringify(loginResponse));

            if (configuration.authentication?.cookies)
                cookie_set(loginResponse.token, 1);

            dispatch(updateAppState('authentication', loginResponse));
            dispatch(unLockScreenRequest());
        }).catch((errorMessage: any) => {
            console.error(errorMessage);
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.authentication.errors.login_failed'),
                type: 'Error'
            } as Components.Toaster_Types.Toaster_Prop_Type);
        });
    };


    return {
        azureAD_Enabled,
        azureAD_isAuthenticated,
        azureAD_Login,
        azureAD_LogOff
    };
}
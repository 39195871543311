import { Components } from '@firedesktop/react-base';
import React from 'react';
import { useParams } from 'react-router-dom';

import _BaseActions from '../../../actions/_BaseActions';

import useResetPasswordWithTokenPage from './useResetPasswordWithTokenPage';


export default function ResetPasswordWithTokenPage() {
    const params = useParams();

    const { getLabel } = _BaseActions();
    const { form, onChange, onClickSend } = useResetPasswordWithTokenPage(params.token ?? '');

    return (
        <div className="login_main">
            <div className="login_form_center">
                <div className="login_box text-center">
                    <div className="login_logo">
                        <Components.AppIcon name={'logo-main'} className={'contract-login-svg'} />
                    </div>
                    <h3 className="fz36 mb-4">{getLabel('resetPasswordWithToken.title')}</h3>
                    <form>
                        <Components.AppInput
                            appIcon={<Components.AppIcon iconHeight={24} iconWidth={24} name={'user'} className={'tertiary-svg'} iconClassName='login_form_svg' />}
                            autoComplete={false}
                            className='appInput_form_for_icon'
                            name='username'
                            onChange={onChange}
                            placeholder={getLabel('resetPasswordWithToken.username_placeholder')}
                            type='text'
                            value={form?.username}
                        /> <br></br>
                        <Components.AppInput
                            appIcon={<Components.AppIcon name={'password'} className={'tertiary-svg'} iconClassName='login_form_pass_svg' />}
                            autoComplete={false}
                            className='appInput_form_for_icon'
                            name='password'
                            onChange={onChange}
                            placeholder={getLabel('resetPasswordWithToken.password_placeholder')}
                            type='password'
                            value={form?.password}
                        /> <br></br>
                        <Components.AppInput
                            appIcon={<Components.AppIcon name={'password'} className={'tertiary-svg'} iconClassName='login_form_pass_svg' />}
                            autoComplete={false}
                            className='appInput_form_for_icon'
                            name='confirmPassword'
                            onChange={onChange}
                            placeholder={getLabel('resetPasswordWithToken.confirmPassword_placeholder')}
                            type='password'
                            value={form?.confirmPassword}
                        />
                        <button type="button" className="login_btn mt-3" onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickSend(e)}>{getLabel('resetPasswordWithToken.send_button')}</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
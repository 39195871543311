import _ from 'lodash';
import { Components, Utils } from '@firedesktop/react-base';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';

import _BaseActions from '../_BaseActions';
import AzureADActions from './AzureADActions';

import { initialState } from '../../Redux/initialState';
import * as Types from '../../Config/Types';
import CookiesActions from './CookiesActions';

/**
* Used to vaalidate password
*  Regex: https://regex101.com/
*/
// Only letters and number
// const passwordRegularExpression_Rule = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
// Letters uppper and lower, Number and special charaters
// const passwordRegularExpression_Rule = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
// Letters uppper and lower, Number mandatory, special charaters NOT mandatory
export const PasswordRegularExpression_Rule = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d|!"£$%&()=?^*+@#-_]{8,}$/;


export default function AuthenticationActions() {
    const authentication = useSelector((state: Types.InitialState_TYPE) => state.authentication);
    const configuration = useSelector((state: Types.InitialState_TYPE) => state.configuration);
    const dispatch = useDispatch();

    const { getLabel, lockScreenRequest, resetAppState, toast, unLockScreenRequest, updateAppState } = _BaseActions();
    const { azureAD_Enabled, azureAD_LogOff } = AzureADActions();
    const { cookie_delete, cookie_set } = CookiesActions();

    const runner = new Utils.Fetch.FetchWrapper();


    const amIAuthenticated = () => {
        return !_.isNil(authentication) && !_.isNil(authentication.token) && !_.isEmpty(authentication.token);
    };

    const checkLocalStorage = async (configuration: Types.ConfigurationState_TYPE | undefined) => {
        if (configuration && configuration.authentication &&
            !_.isNil(configuration.authentication.localStorageName) && !_.isEmpty(configuration.authentication.localStorageName)) {
            console.log(`Trying to load data from Local Storage using this name: ${configuration.authentication.localStorageName}`);
            const storageData = localStorage.getItem(configuration.authentication.localStorageName);
            if (storageData) {
                console.log(`Loaded data from Local Storage using this name: ${configuration.authentication?.localStorageName}`);
                const authentication = JSON.parse(storageData);
                dispatch(updateAppState('authentication', authentication));
            }
            else
                console.log(`Not Loaded data from Local Storage using this name: ${configuration.authentication?.localStorageName}`);
        }
    };

    /**
     * HTTP 401 Unauthorized
     */
    const actionOn_401 = async (): Promise<void> => {
        await logOut(getLabel('common.authentication.errors.session_expired'), undefined, undefined);
    };

    /**
     * HTTP 403 Forbidden
     */
    const actionOn_403 = async (): Promise<void> => {
        toast({
            title: getLabel('common.authentication.errors.forbidden'),
            type: 'Warning'
        });
    };

    // **************************************************************************************************
    //              Login/out
    // **************************************************************************************************
    const logIn = async (username: string, password: string) => {
        const url = `${configuration.api?.authentication}Authentication/LoginWithForm`;

        dispatch(lockScreenRequest());

        return await runner.post(url, undefined, undefined, undefined, { username, password }).then((authentication: Types.AuthenticationState_TYPE) => {
            if (configuration.authentication?.localStorageName)
                localStorage.setItem(configuration.authentication.localStorageName, JSON.stringify(authentication));

            if (configuration.authentication?.cookies)
                cookie_set(authentication.token, 1);

            dispatch(updateAppState('authentication', authentication));
            dispatch(unLockScreenRequest());
        }).catch((errorMessage: any) => {
            console.error(errorMessage);
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.authentication.errors.login_failed'),
                type: 'Error'
            } as Components.Toaster_Types.Toaster_Prop_Type);
        });
    };

    const logInOAuth = async (application_id: string, application_secret: string, redirect_uri: string, username: string, password: string): Promise<Types.OAuth2LoginResponse_Type | undefined> => {
        const url = `${configuration.api?.authentication}Authentication/oauth2/token`;

        const params = {
            application_id,
            application_secret,
            password,
            redirect_uri,
            username
        };

        dispatch(lockScreenRequest());
        return await runner.post(url, undefined, undefined, undefined, params).then((oauth2Response: Types.OAuth2LoginResponse_Type) => {
            dispatch(unLockScreenRequest());
            return oauth2Response;
        }).catch((errorMessage: any) => {
            console.error(errorMessage);
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.authentication.errors.loginOAuth_failed'),
                type: 'Error'
            } as Components.Toaster_Types.Toaster_Prop_Type);
            return undefined;
        });
    };

    const logOut = async (message: string | undefined | null, routeAddress: string | undefined, navigate: NavigateFunction | undefined) => {
        const _initialState = initialState();
        if (message) {
            toast({
                title: message,
                type: 'Warning'
            });
        }

        // Keep language from authentication
        const authenticationNew = { ..._initialState.authentication, language: authentication.language };

        if (configuration.authentication?.localStorageName)
            localStorage.setItem(configuration.authentication.localStorageName, JSON.stringify(authenticationNew));

        if (configuration.authentication?.cookies)
            cookie_delete();

        await azureAD_LogOff();

        dispatch(resetAppState({ ..._initialState, authentication: authenticationNew }));
        if (!_.isNil(routeAddress) && !_.isEmpty(routeAddress) && navigate) {
            navigate(routeAddress, { replace: true });
        }
    };
    // **************************************************************************************************
    //              Password
    // **************************************************************************************************
    const changePassword = async (oldPassword: string, password: string, passwordConfirm: string): Promise<boolean> => {
        const url = `${configuration.api?.authentication}User/ResetPassword`;

        if (password !== passwordConfirm || !PasswordRegularExpression_Rule.test(password)) {
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.authentication.errors.changePassword_wrongNewPassord'),
                type: 'Warning'
            } as Components.Toaster_Types.Toaster_Prop_Type);
            return false;
        }

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token, undefined, {
            oldPassword,
            password,
            passwordConfirm
        }).then(() => {
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.success'),
                content: getLabel('common.api.success.changePassword.changePassword_success'),
                type: 'Success'
            });
            return true;

        }).catch((errorMessage: any) => {
            console.error(errorMessage);
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.authentication.errors.changePassword_failed'),
                type: 'Error'
            } as Components.Toaster_Types.Toaster_Prop_Type);
            return false;
        });
    };

    const changePasswordRequest = async (usernameOrEmail: string): Promise<boolean> => {
        const url = `${configuration.api?.authentication}User/ResetPasswordWithTokenRequest`;
        const urlWithoutToken = window.location.origin;

        if (usernameOrEmail.length === 0)
            return false;

        dispatch(lockScreenRequest());

        return await runner.post(url, undefined, undefined, undefined, { usernameOrEmail, urlWithoutToken }).then(() => {
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.success'),
                content: getLabel('common.api.success.resetPassword.resetPassword_success'),
                type: 'Success'
            });
            return true;

        }).catch((errorMessage: any) => {
            console.error(errorMessage);
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.authentication.errors.resetPassword_failed'),
                type: 'Error'
            });
            return false;
        });
    };

    const changePasswordWithToken = async (userName: string, password: string, passwordConfirm: string, token: string | null) => {
        const url = `${configuration.api?.authentication}User/ResetPasswordWithToken`;

        if (password !== passwordConfirm || !PasswordRegularExpression_Rule.test(password)) {
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.authentication.errors.changePassword_wrongNewPassord'),
                type: 'Warning'
            } as Components.Toaster_Types.Toaster_Prop_Type);
            return;
        }

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token, undefined, {
            userName,
            password,
            passwordConfirm,
            token
        }).then(async () => {
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.success'),
                content: getLabel('common.api.success.changePassword.changePassword_success'),
                type: 'Success'
            });

            await logIn(userName, password);
            window.location.href = '/';
        }).catch((errorMessage: any) => {
            console.error(errorMessage);
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.authentication.errors.changePassword_failed'),
                type: 'Error'
            } as Components.Toaster_Types.Toaster_Prop_Type);
        });
    };

    const changePasswordWithToken_ValidateToken = async (token: string | null) => {
        if (_.isNil(token) || _.isEmpty(token))
            return false;

        const url = `${configuration.api?.authentication}User/ResetPasswordWithTokenValidateToken/${token}`;

        dispatch(lockScreenRequest());
        return await runner.get(url, configuration.applicationName, authentication.token).then((dto: { valid: boolean }) => {
            dispatch(unLockScreenRequest());
            return dto.valid;
        }).catch((errorMessage: any) => {
            console.error(errorMessage);
            dispatch(unLockScreenRequest());
            return false;
        });
    };

    // **************************************************************************************************
    //              Tenant and Authority
    // **************************************************************************************************
    async function selectAuthority(authorityId: number) {
        const url = `${configuration.api?.authentication}Authentication/SelectAuthority/${authorityId}`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token, undefined, {}).then((loginResponse: Types.AuthenticationState_TYPE) => {
            if (configuration.authentication?.localStorageName)
                localStorage.setItem(configuration.authentication.localStorageName, JSON.stringify(loginResponse));

            if (configuration.authentication?.cookies)
                cookie_set(loginResponse.token, 1);

            dispatch(updateAppState('authentication', loginResponse));
            dispatch(unLockScreenRequest());
        }).then(() => {
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.success'),
                content: getLabel('common.api.success.selectAuthority.selectAuthority_success'),
                type: 'Success'
            });
        }).catch((errorMessage: any) => {
            console.error(errorMessage);
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.authentication.errors.selectAuthority_failed'),
                type: 'Error'
            } as Components.Toaster_Types.Toaster_Prop_Type);
        });
    };

    async function selectTenant(tenantId: string) {
        const url = `${configuration.api?.authentication}Authentication/SelectTenant/${tenantId}`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token, undefined, {}).then((loginResponse: Types.AuthenticationState_TYPE) => {
            if (configuration.authentication?.localStorageName)
                localStorage.setItem(configuration.authentication.localStorageName, JSON.stringify(loginResponse));

            dispatch(updateAppState('authentication', loginResponse));
            dispatch(unLockScreenRequest());
        }).then(() => {
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.success'),
                content: getLabel('common.api.success.selectTenant.selectTenant_success'),
                type: 'Success'
            });
        }).catch((errorMessage: any) => {
            console.error(errorMessage);
            dispatch(unLockScreenRequest());
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.api.errors.authentication.selectTenant_failed'),
                type: 'Error'
            } as Components.Toaster_Types.Toaster_Prop_Type);
        });
    };

    // *************************************************************************************************************************
    //                          Common values
    // *************************************************************************************************************************
    const getContactStatuses = async (): Promise<Types.ContactStatus_Type[]> => {
        const url = `${configuration.api?.authentication}ContactStatus`;

        dispatch(lockScreenRequest());
        return await runner.get(url, configuration.applicationName, authentication.token).then((loadedItems: any) => {
            dispatch(unLockScreenRequest());
            return loadedItems;
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(errorMessage);
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.api.errors.authentication.contactStatuses_search'),
                type: 'Error'
            });
            return {
                items: [],
                itemsPerPage: 1,
                page: 1,
                sort: '',
                totalItems: 0
            };
        });
    };

    const getContactTypes = async (): Promise<Types.ContactType_Type[]> => {
        const url = `${configuration.api?.authentication}ContactType`;

        return await runner.get(url, configuration.applicationName, authentication.token).then((contactTypes: Types.ContactType_Type[]) => {
            return contactTypes.sort(function (a, b) {
                if (!a.description) return -1;
                if (!b.description) return 1;
                if (a.description < b.description) return -1;
                if (a.description > b.description) return 1;
                return 0;
            });
        }).catch((errorMessage: any) => {
            console.error(errorMessage);
            return [];
        });
    };

    return {
        amIAuthenticated,
        azureAD_Enabled,
        checkLocalStorage,
        cookie_delete,
        cookie_set,
        logIn,
        logInOAuth,
        logOut,
        changePassword,
        changePasswordRequest,
        changePasswordWithToken,
        changePasswordWithToken_ValidateToken,
        getContactStatuses,
        getContactTypes,
        selectAuthority,
        selectTenant,
        actionOn_401,
        actionOn_403
    };
}

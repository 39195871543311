import _ from 'lodash';
import { Components, Utils } from '@firedesktop/react-base';
import { useDispatch, useSelector } from 'react-redux';

import * as Types from '../Config/Types';
import * as Types_Rpa from '../Config/Types_Rpa';


export default function _BaseActions() {
    const configuration = useSelector((state: Types.InitialState_TYPE) => state.configuration);
    const configurationBE_Recognition = useSelector((state: Types.InitialState_TYPE) => state.configurationBE_Recognition);
    const configurationBE_Runtime = useSelector((state: Types.InitialState_TYPE) => state.configurationBE_Runtime);
    const freeSpace = useSelector((state: Types.InitialState_TYPE) => state.freeSpace);
    const labels = useSelector((state: Types.InitialState_TYPE) => state.labels);

    const dispatch = useDispatch();
    const lLoader = Utils.Labels.LanguageManager();


    const fromBeError = async (status: number, error: any, alterntiveAction: () => void) => {
        let message = '';
        if (typeof error === 'string')
            message = error;
        else if (error.text)
            message = await error.text();

        if (status === 400 && message) {
            toast({
                title: getLabel('common.toaster.title.error'),
                content: message,
                type: 'Error'
            });
        }
        else
            alterntiveAction();
    };
    // ******************************************************************************************************
    //                  State
    // ******************************************************************************************************
    const resetAppState = (newState: Types.InitialState_TYPE) => {
        return {
            type: Types.ACTIONS.UPDATE_BULK_APP_STATE,
            payload: {
                values: { ...newState, configuration, configurationBE_Recognition, configurationBE_Runtime, labels }
            }
        };
    };

    const configurationAppState = (configuration: Types.ConfigurationState_TYPE,
        configurationBE_Recognition: Types.Configuration_BE_Recognition_State_TYPE,
        configurationBE_Runtime: Types_Rpa.Configuration_BE_Runtime_State_TYPE,
        labels: any) => {
        return {
            type: Types.ACTIONS.UPDATE_BULK_APP_STATE,
            payload: {
                values: { configuration, configurationBE_Recognition, configurationBE_Runtime, freeSpace, labels }
            }
        };
    };

    const updateAppState = (name: Types.State_NAMES, value: any) => {
        return {
            type: Types.ACTIONS.UPDATE_APP_STATE,
            payload: {
                name,
                value
            }
        };
    };

    const updateAppStateGeneric = (name: string, value: any) => {
        return {
            type: Types.ACTIONS.UPDATE_APP_STATE,
            payload: {
                name,
                value
            }
        };
    };

    const updateAppStateFreeSpace = (name: string, value: any) => {
        return {
            type: Types.ACTIONS.UPDATE_APP_STATE,
            payload: {
                name: 'freeSpace',
                value: { ...freeSpace, [name]: value }
            },
        };
    };

    const lockScreenRequest = () => {
        return {
            type: Types.ACTIONS.SCREEN_LOCK_REQUEST,
        };
    };

    const unLockScreenRequest = () => {
        return {
            type: Types.ACTIONS.SCREEN_UNLOCK_REQUEST,
        };
    };

    const toast = (toastObject: Components.Toaster_Types.Toaster_Prop_Type) => {
        dispatch(updateAppState('toaster', toastObject));
    };

    // ******************************************************************************************************
    //                  Labels
    // ******************************************************************************************************
    const getLabel_EntireObject = (id: string): any => {
        return lLoader.getLabel(labels, id);
    };

    const getLabel = (id: string): string => {
        return lLoader.getLabel(labels, id);
    };

    const getLabelValueArraySorted = (id: string): Array<Types.Common_Label_Value_Type> => {
        const appArr = lLoader.getLabel(labels, id);
        return Array.isArray(appArr) ? appArr.sort(function (a, b) {
            if (_.toNumber(a.label) < _.toNumber(b.label)) return -1;
            if (_.toNumber(a.label) > _.toNumber(b.label)) return 1;
            return 0;
        }) : [];
    };

    const getLabelFromMyLabels = (labels: object, id: string): string => {
        return lLoader.getLabel(labels, id);
    };

    const getLabelFromMyLabelsByStateName = (labelStateName: string, id: string): any => {
        return lLoader.getLabel(freeSpace[labelStateName], id);
    };

    return {
        configurationAppState,
        fromBeError,
        getLabel,
        getLabel_EntireObject,
        getLabelFromMyLabels,
        getLabelFromMyLabelsByStateName,
        getLabelValueArraySorted,
        lockScreenRequest,
        resetAppState,
        toast,
        updateAppState,
        updateAppStateFreeSpace,
        updateAppStateGeneric,
        unLockScreenRequest
    };
}
/*
*  https://web.dev/articles/vitals
*/
import { Metric, onCLS, onINP, onLCP } from 'web-vitals';


const reportWebVitals = () => {
  function logDelta(metric: Metric) {
    console.log(`WebVitals: ${metric.name} matching ID ${metric.id} changed by ${metric.delta}`);
  }

  onCLS(logDelta, { reportAllChanges: true });
  onINP(logDelta, { reportAllChanges: true });
  onLCP(logDelta, { reportAllChanges: true });
};

export default reportWebVitals;

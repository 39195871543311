import {
    BpmnDiagrams, UndoRedo, DiagramContextMenu, DataBinding,
    Inject,
    NodeModel,
    SymbolInfo,
    SymbolPaletteComponent
} from '@syncfusion/ej2-react-diagrams';
import React from 'react';

import * as Bobo from './Shapes';

const PaletteComponent = () => {

    const getNodeDefaults = (symbol: NodeModel) => {
        symbol.width = 60;
        symbol.height = 60;
        if (symbol.shape?.type === 'Flow') {
            symbol.width = 60;
            symbol.height = 40;
            symbol.style = {
                fill: '#6BA5D7',
                opacity: 0.2,
                strokeColor: 'black',
                strokeWidth: 1,
                strokeDashArray: '',
                gradient: {
                    type: 'None'
                }
            };
        }
    };

    return (
        <React.Fragment>
            <SymbolPaletteComponent
                allowDrag={true}
                id="symbolpalette"
                expandMode="Multiple"
                enableAnimation={true}
                palettes={[
                    {
                        id: 'basic',
                        expanded: false,
                        symbols: Bobo.BasicShapes,
                        title: 'Basic Shapes',
                        iconCss: 'e-diagram-icons1 e-diagram-basic'
                    },
                    {
                        id: 'bpmn',
                        expanded: false,
                        symbols: Bobo.BPMNShapes,
                        iconCss: 'e-diagram-icons1 e-diagram-flow',
                        title: 'BPMN'
                    },
                    {
                        id: 'flow',
                        expanded: false,
                        symbols: Bobo.FlowShapes,
                        iconCss: 'e-diagram-icons1 e-diagram-flow',
                        title: 'Flow Shapes'
                    },
                    {
                        id: 'connectors',
                        expanded: false,
                        symbols: Bobo.ConnectorSymbols,
                        iconCss: 'e-diagram-icons1 e-diagram-connector',
                        title: 'Connectors'
                    }
                ]}
                getNodeDefaults={getNodeDefaults}
                getSymbolInfo={(symbol: NodeModel): SymbolInfo => {
                    return { fit: true };
                }}
                symbolHeight={60}
                symbolWidth={60}
                symbolMargin={{ left: 5, right: 5, top: 5, bottom: 5 }} >
                <Inject services={[BpmnDiagrams, UndoRedo, DiagramContextMenu, DataBinding]}
                />
            </SymbolPaletteComponent>
        </React.Fragment>
    );
};

export default PaletteComponent;
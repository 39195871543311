import { Components } from '@firedesktop/react-base';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import * as Constants from '../../../Config/Constants';

import useOAuthLoginPage from './useOAuthLoginPage';
/**
 * TO Invoke you have to endode url
 * URL Sample: http://localhost:3000/#/OAuthLogin?application_id=8917FFC6-6639-4245-B379-211DE0748A06&application_secret=6CA44E23-0C2C-4A0E-907A-78BFBF029E05&redirect_uri=http%3A%2F%2Fwww.bing.com&state=security_token=asdadsada
 * 
 * For more information we made this following Google Style:
 *      https://developers.google.com/identity/openid-connect/openid-connect
 */
export default function OAuthLoginPage() {
    const [searchParams] = useSearchParams();

    const {
        getLabel, localState, onChange, onClickLogin
    } = useOAuthLoginPage(
        searchParams.get('application_id') ?? undefined,
        searchParams.get('application_secret') ?? undefined,
        searchParams.get('redirect_uri') ?? undefined,
        searchParams.get('state') ?? undefined,);

    const subTitle = getLabel('loginPage.subTitle') !== 'loginPage.subTitle' ? getLabel('loginPage.subTitle') : '';
    return (
        <>
            <div className="login_main">
                <div className="login_form_center">
                    <div className="login_box text-center">
                        <div className="login_logo">
                            <Components.AppIcon name={'logo-main'} className={'contract-login-svg'}
                                fill={Constants.BasicTheme.baseColor} stroke={Constants.BasicTheme.baseColor} />
                        </div>
                        <h3 className="fz36 mb-0">{getLabel('loginPage.title')}</h3>
                        {subTitle ? <h3 className="fz20 mb-3">{subTitle}</h3> : ''}
                        {localState.application_id && localState.application_secret && localState.redirect_uri && localState.state ?
                            <form>
                                <Components.AppInput
                                    appIcon={<Components.AppIcon iconHeight={24} iconWidth={24} name={'user'} className={'tertiary-svg'} iconClassName='login_form_svg' />}
                                    className='appInput_form_for_icon'
                                    name='username'
                                    onChange={onChange}
                                    placeholder={getLabel('loginPage.username_placeholder')}
                                    type='text'
                                    value={localState.username}
                                />
                                <Components.AppInput
                                    appIcon={<Components.AppIcon name={'password'} className={'tertiary-svg'} iconClassName='login_form_pass_svg' />}
                                    className='appInput_form_for_icon'
                                    name='password'
                                    onChange={onChange}
                                    placeholder={getLabel('loginPage.password_placeholder')}
                                    type='password'
                                    value={localState.password}
                                />
                                <button className="login_btn mt-3" onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickLogin(e)}>{getLabel('loginPage.login_button')}</button>
                            </form>
                            :
                            <h2 className="fz20 mb-3">
                                application_id, application_secret, redirect_uri and state are mandatory
                            </h2>}
                    </div>
                </div>
            </div>
        </>
    );
}

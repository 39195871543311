import {
    ConnectorModel,
    NodeConstraints,
    NodeModel
} from '@syncfusion/ej2-react-diagrams';

const ConnectorSymbols: ConnectorModel[] = [
    {
        id: 'Link1',
        type: 'Orthogonal',
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        targetDecorator: { shape: 'Arrow', style: { strokeColor: '#757575', fill: '#757575' } },
        style: { strokeWidth: 1, strokeColor: '#757575' }
    },
    {
        id: 'link3',
        type: 'Orthogonal',
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        style: { strokeWidth: 1, strokeColor: '#757575' },
        targetDecorator: { shape: 'None' },
    },
    {
        id: 'Link21',
        type: 'Straight',
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        targetDecorator: { shape: 'Arrow', style: { strokeColor: '#757575', fill: '#757575' } },
        style: { strokeWidth: 1, strokeColor: '#757575' }
    },
    {
        id: 'link23',
        type: 'Straight',
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        style: { strokeWidth: 1, strokeColor: '#757575' },
        targetDecorator: { shape: 'None' }
    },
    {
        id: 'link33',
        type: 'Bezier',
        sourcePoint: { x: 0, y: 0 },
        targetPoint: { x: 60, y: 60 },
        style: { strokeWidth: 1, strokeColor: '#757575' },
        targetDecorator: { shape: 'None' }
    }
];

const BasicShapes: NodeModel[] = [
    { id: 'Rectangle', shape: { type: 'Basic', shape: 'Rectangle' }, annotations: [{ content: 'Rectangle' }] },
    { id: 'Ellipse', shape: { type: 'Basic', shape: 'Ellipse' }, annotations: [{ content: 'Ellipse' }] },
    { id: 'Triangle', shape: { type: 'Basic', shape: 'Triangle' }, annotations: [{ content: 'Triangle' }] },
    { id: 'Plus', shape: { type: 'Basic', shape: 'Plus' }, annotations: [{ content: 'Plus' }] },
    { id: 'Star', shape: { type: 'Basic', shape: 'Star' }, annotations: [{ content: 'Star' }] },
    { id: 'Pentagon', shape: { type: 'Basic', shape: 'Pentagon' }, annotations: [{ content: 'Pentagon' }] },
    { id: 'Heptagon', shape: { type: 'Basic', shape: 'Heptagon' }, annotations: [{ content: 'Heptagon' }] },
    { id: 'Octagon', shape: { type: 'Basic', shape: 'Octagon' }, annotations: [{ content: 'Octagon' }] },
    { id: 'Trapezoid', shape: { type: 'Basic', shape: 'Trapezoid' }, annotations: [{ content: 'Trapezoid' }] },
    { id: 'Decagon', shape: { type: 'Basic', shape: 'Decagon' }, annotations: [{ content: 'Decagon' }] },
    { id: 'RightTriangle', shape: { type: 'Basic', shape: 'RightTriangle' }, annotations: [{ content: 'Right Triangle' }] },
    { id: 'Parallelogram', shape: { type: 'Basic', shape: 'Parallelogram' }, annotations: [{ content: 'Parallelogram' }] }
];

const FlowShapes: NodeModel[] = [
    { id: 'Terminator', shape: { type: 'Flow', shape: 'Terminator' }, annotations: [{ content: 'Terminator' }] },
    { id: 'Process', shape: { type: 'Flow', shape: 'Process' }, annotations: [{ content: 'Process' }] },
    { id: 'Decision', shape: { type: 'Flow', shape: 'Decision' }, annotations: [{ content: 'Decision' }] },
    { id: 'Document', shape: { type: 'Flow', shape: 'Document' }, annotations: [{ content: 'Document' }] },
    { id: 'PreDefinedProcess', shape: { type: 'Flow', shape: 'PreDefinedProcess' }, annotations: [{ content: 'Pre Defined Process' }] },
    { id: 'PaperTap', shape: { type: 'Flow', shape: 'PaperTap' }, annotations: [{ content: 'Paper Tap' }] },
    { id: 'DirectData', shape: { type: 'Flow', shape: 'DirectData' }, annotations: [{ content: 'Direct Data' }] },
    { id: 'SequentialData', shape: { type: 'Flow', shape: 'SequentialData' }, annotations: [{ content: 'Sequential Data' }] },
    { id: 'Sort', shape: { type: 'Flow', shape: 'Sort' }, annotations: [{ content: 'Sort' }] },
    { id: 'MultiDocument', shape: { type: 'Flow', shape: 'MultiDocument' }, annotations: [{ content: 'Multi Document' }] },
    { id: 'Collate', shape: { type: 'Flow', shape: 'Collate' }, annotations: [{ content: 'Collate' }] },
    { id: 'SummingJunction', shape: { type: 'Flow', shape: 'SummingJunction' }, annotations: [{ content: 'Summing Junction' }] },
    { id: 'Or', shape: { type: 'Flow', shape: 'Or' }, annotations: [{ content: 'Or' }] },
    { id: 'InternalStorage', shape: { type: 'Flow', shape: 'InternalStorage' }, annotations: [{ content: 'Internal Storage' }] },
    { id: 'Extract', shape: { type: 'Flow', shape: 'Extract' }, annotations: [{ content: 'Extract' }] },
    { id: 'ManualOperation', shape: { type: 'Flow', shape: 'ManualOperation' }, annotations: [{ content: 'Manual Operation' }] },
    { id: 'Merge', shape: { type: 'Flow', shape: 'Merge' }, annotations: [{ content: 'Merge' }] },
    { id: 'OffPageReference', shape: { type: 'Flow', shape: 'OffPageReference' }, annotations: [{ content: 'Off Page Reference' }] },
    { id: 'SequentialAccessStorage', shape: { type: 'Flow', shape: 'SequentialAccessStorage' }, annotations: [{ content: 'Sequential Access Storage' }] },
    { id: 'Annotation', shape: { type: 'Flow', shape: 'Annotation' }, annotations: [{ content: 'Annotation 1' }] },
    { id: 'Data', shape: { type: 'Flow', shape: 'Data' }, annotations: [{ content: 'AData' }] },
    { id: 'Card', shape: { type: 'Flow', shape: 'Card' }, annotations: [{ content: 'Card' }] },
    { id: 'Delay', shape: { type: 'Flow', shape: 'Delay' }, annotations: [{ content: 'Delay' }] },
];

const BPMNShapes: NodeModel[] = [
    {
        id: 'Start', width: 35, height: 35, shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'Start' }
        }
    },
    {
        id: 'NonInterruptingIntermediate', width: 35, height: 35, shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'NonInterruptingIntermediate' }
        },
    },
    {
        id: 'End', width: 35, height: 35, offsetX: 665, offsetY: 230, shape: {
            type: 'Bpmn', shape: 'Event',
            event: { event: 'End' }
        },
    },
    {
        id: 'Task', width: 35, height: 35, offsetX: 700, offsetY: 700,
        shape: {
            type: 'Bpmn', shape: 'Activity', activity: {
                activity: 'Task',
            },
        }
    },
    {
        id: 'Transaction', width: 35, height: 35, offsetX: 300, offsetY: 100,
        constraints: NodeConstraints.Default | NodeConstraints.AllowDrop,
        shape: {
            type: 'Bpmn', shape: 'Activity',
            activity: {
                activity: 'SubProcess', subProcess: {
                    type: 'Transaction', transaction: {
                        cancel: { visible: false }, failure: { visible: false }, success: { visible: false }
                    }
                }
            }
        }
    }, {
        id: 'Task_Service', width: 35, height: 35, offsetX: 700, offsetY: 700,
        shape: {
            type: 'Bpmn', shape: 'Activity', activity: {
                activity: 'Task', task: { type: 'Service' }
            },
        }
    },
    {
        id: 'Gateway', width: 35, height: 35, offsetX: 100, offsetY: 100,
        shape: { type: 'Bpmn', shape: 'Gateway', gateway: { type: 'Exclusive' } },
    },
    {
        id: 'DataObject', width: 35, height: 35, offsetX: 500, offsetY: 100,
        shape: { type: 'Bpmn', shape: 'DataObject', dataObject: { collection: false, type: 'None' } }
    }, {
        id: 'subProcess', width: 520, height: 250, offsetX: 355, offsetY: 230,
        constraints: NodeConstraints.Default | NodeConstraints.AllowDrop,
        shape: {
            shape: 'Activity', type: 'Bpmn',
            activity: {
                activity: 'SubProcess', subProcess: {
                    type: 'Transaction', collapsed: false,
                    processes: [], transaction: {
                        cancel: { visible: false }, failure: { visible: false }, success: { visible: false }
                    }
                }
            }
        }
    },
];

export { BasicShapes, BPMNShapes, ConnectorSymbols, FlowShapes };

export async function createFetch(method: 'DELETE' | 'GET' | 'POST' | 'PUT', url: string, applicationName: string | undefined, token: string | undefined) {
    try {
        const headers = new Headers();

        if (applicationName)
            headers.append('ApplicationName', applicationName);
        if (token)
            headers.append('SessionToken', token);

        headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');
        headers.append('pragma', 'no-cache');
        headers.append('Expires', '0');
        headers.append('X-Content-Type-Options', 'nosniff');
        const requestInit = {
            method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'omit',
            headers
        } as RequestInit;

        return fetch(url, requestInit).then(() => { });
    }
    catch (err) { }
}
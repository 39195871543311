import { useState } from 'react';
import AuthenticationActions from '../../../actions/Authentication/AuthenticationActions';

function useLoginPage() {
    const [form, setForm] = useState({ username: '', password: '' });

    const { logIn } = AuthenticationActions();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const onClickLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        logIn(form.username, form.password);
    };

    return {
        form,
        onChange,
        onClickLogin
    };
}

export default useLoginPage;
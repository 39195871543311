import { HubConnectionBuilder, HubConnection, HubConnectionState } from '@microsoft/signalr';

import * as Types from '../Config/Types';

/**
 *  ASP.NET Core SignalR JavaScript client
 *      https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-3.1&tabs=visual-studio
 */
export default abstract class _BaseHub {
    connection: HubConnection | undefined;

    public abstract getMyName(): string;

    public abstract getMyHubUrl(configuration: Types.ConfigurationState_TYPE): string | undefined;

    protected abstract setHubConnectionActions(): void;

    // *********************************************************************************************************
    //      Start / Stop
    // *********************************************************************************************************
    start = (authentication: Types.AuthenticationState_TYPE, configuration: Types.ConfigurationState_TYPE) => {
        const url = this.getMyHubUrl(configuration);
        if (!url || url === '') {
            console.log(`${this.getMyName()} - Hub remote Server Not Configured url: ${url}`);
            return;
        }

        console.log(`${this.getMyName()} - Hub Starting connection on this url: ${url}`);

        if (this.connection && this.connection.state === HubConnectionState.Connected) {
            this.connection.stop()
                .then(result => {
                    console.log(`${this.getMyName()} - Hub Stopped!`);
                    this.connect(authentication, url);
                })
                .catch(e => {
                    console.warn(`${this.getMyName()} - Hub still Connected, error: `, e);
                });
        }
        else
            this.connect(authentication, url);

    };

    stop = () => {
        if (this.connection) {
            console.log(`${this.getMyName()} - Hub closing connection`);
            this.connection.stop()
                .then(result => {
                    console.log(`${this.getMyName()} - Hub Stopped!`);
                })
                .catch(e => {
                    console.warn(`${this.getMyName()} - Hub still Connected, error: `, e);
                });
        }
    };

    // *********************************************************************************************************
    //      Private Methods
    // *********************************************************************************************************
    private connect = (authentication: Types.AuthenticationState_TYPE, url: string) => {
        this.connection = new HubConnectionBuilder()
            .withUrl(url, {
                accessTokenFactory: () => authentication.token,
                // // Not working
                // headers: { 'SessionToken': authentication.token }
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    if (retryContext.elapsedMilliseconds < 1800000) { // 30 minutes
                        // If we've been reconnecting for less than 30 minutes so far,
                        // wait between 0 and 10 seconds before the next reconnect attempt.
                        return Math.random() * 10000;
                    } else {
                        // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        return null;
                    }
                }
            })
            .build();

        if (this.connection) {
            console.log(`${this.getMyName()} - Hub starting connection`);

            this.connection.onreconnecting(error => {
                if (this.connection) {
                    console.info(`${this.getMyName()} - Hub Reconnecting: ${this.connection.state === HubConnectionState.Reconnecting}, error`, error);
                    // TODO: Notify User
                }
            });

            this.connection.onreconnected(connectionId => {
                if (this.connection) {
                    console.log(`${this.getMyName()} - Hub Reconnected, ConnectionId: ${connectionId}`);
                    // TODO: Notify User
                }
            });

            this.connection.onclose(error => {
                if (this.connection) {
                    console.error(`${this.getMyName()} - Hub Disconnected: ${this.connection.state === HubConnectionState.Disconnected}, error`, error);
                    // TODO: Notify User
                }
            });

            this.connection.start()
                .then(result => {
                    this.setHubConnectionActions();
                })
                .catch(e => {
                    console.warn(`${this.getMyName()} - Hub Connection failed, error: `, e);
                });
        }
    };
}
export enum DateOperatorsTypes_ENUM {
    Between = 1,
    DifferentFrom = 2,
    EqualTo = 3,
    GreaterOrEqualThen = 4,
    GreaterThen = 5,
    lessOrEqualThen = 6,
    lessThen = 7
}
export const DateOperatorsTypes__For_Select = (getLabel: (id: string) => string) => {
    return [
        { id: DateOperatorsTypes_ENUM.Between, label: getLabel('common.date_operator.between') },
        { id: DateOperatorsTypes_ENUM.DifferentFrom, label: getLabel('common.date_operator.differentFrom') },
        { id: DateOperatorsTypes_ENUM.EqualTo, label: getLabel('common.date_operator.equalTo') },
        { id: DateOperatorsTypes_ENUM.GreaterOrEqualThen, label: getLabel('common.date_operator.greaterOrEqualThen') },
        { id: DateOperatorsTypes_ENUM.GreaterThen, label: getLabel('common.date_operator.greaterThen') },
        { id: DateOperatorsTypes_ENUM.lessOrEqualThen, label: getLabel('common.date_operator.lessOrEqualThen') },
        { id: DateOperatorsTypes_ENUM.lessThen, label: getLabel('common.date_operator.lessThen') }
    ].sort(function (a, b) {
        if (!a.label) return -1;
        if (!b.label) return 1;
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    });
};

export enum FieldContentTypes_ENUM {
    Capture = 0,
    Constant = 1,
    Script = 2
}

export enum FileType_ENUM {
    Unknown = 0,
    Word = 1,
    Pdf = 2,
    Excel = 3,
    Tiff = 4,
    Zip = 5,
    Csv = 6,
    P7m = 7,
    ExcelMacro = 8
}

export enum SignType_ENUM {
    Cades = 1,
    Xades = 2,
    Pades = 3,
    Hash = 4
}

export enum InputType_ENUM {
    Text = 0,
    Date = 1,
    DropDown = 2,
    CheckBox = 3,
    MenuItem = 4,
    TextArea = 5,
    List = 6,
    File = 20,
    Email = 40,
}

export const FileTypes_For_Select = () => {
    return [
        { id: FileType_ENUM.Csv, name: 'Csv', ext: 'csv' },
        { id: FileType_ENUM.Excel, name: 'Excel', ext: 'xlsx' },
        { id: FileType_ENUM.Pdf, name: 'Pdf', ext: 'pdf' },
        { id: FileType_ENUM.Tiff, name: 'Tiff', ext: 'tiff' },
        { id: FileType_ENUM.Unknown, name: 'Unknown', ext: 'ukn' },
        { id: FileType_ENUM.Word, name: 'Word', ext: 'docx' },
        { id: FileType_ENUM.Zip, name: 'Zip', ext: 'zip' },
        { id: FileType_ENUM.P7m, name: 'P7m', ext: 'p7m' },
        { id: FileType_ENUM.ExcelMacro, name: 'Excel Marco', ext: 'xlsm' }
    ];
};

export const FileTypeDescription = (typeId: FileType_ENUM): { id: FileType_ENUM, name: string, ext: string } | undefined => {
    const app = FileTypes_For_Select();
    return app.filter(x => x.id === typeId)[0];
};

export const FileTypeToExtensionString = (typeId: FileType_ENUM): string => {
    const fileTypeDescription = FileTypeDescription(typeId);
    return fileTypeDescription ? fileTypeDescription.ext : '';
};


export enum Instance_MatchingStatus_ENUM {
    NoKeyFound = 0,
    NoTemplateMatching = 1,
    NotAllFieldsMatching = 2,
    TemplateNotAssociated = 3,
    Valid = 4,
    RuleFailed = 5,
    InvalidFileFormat = 6,
    InvalidImage = 7,
    MissingFile = 8,
    Discarded = 9
}

export enum Instance_ProcessingStatus_ENUM {
    NotProcessed = 0,
    Processing = 1,
    Processed = 2,
    ProcessingFailed = 3,
    ToBeProcessed = 10,
    CanBeDeleted = 11
}

export enum RpaScriptCommandShape_Type_ENUM {
    Process = 0,
    Terminator = 1,
    Decisor = 2,
    Starter = 3
}

export enum RpaScriptCommandShapeType_Type_ENUM {
    Basic = 0,
    Flow = 1,
    Image = 2,
    Native = 3
}

export enum RpaCommandTYPE_Type_ENUM {
    Process = 0,
    BeginCommand = 1,
    EndCommand = 2,
    BeginLoopCommand = 3,
    EndFreeCommand = 4,
    Switch_3 = 5
}

export enum RpaCommandPropertyType_ENUM {
    String = 0,
    Number = 1,
    List = 2,
    MultipleList = 3
}

export enum CommandType_ENUM {
    Silent = 0,
    PopUp = 1,      // moadale
    Form = 2,
    Menu = 3,
    ContextMenu = 4,
    Report = 5,
    New = 6,
    Edit = 7,
    Navigation = 8,
    Grid = 9,
    List = 10,
    Expand = 11,
    Shrink = 12,
    FEPopUp = 25,
    FEExcel = 26,
    Embedded = 27   // It will be open in a div of the same page
}

export enum WF_NodeState_ENUM {
    Closed = 0,
    Started = 1,
    Running = 2,
    OnHalt = 3,
    OnError = 4,
    NotValid = 5,
}

export enum WF_AuthorizationState_ENUM {
    NotYetSigned = 0,
    Rejected = 1,
    Signed = 2,
    Invalid_NotYetSigned = 10,
    Invalid_Rejected = 11,
    Invalid_Signed = 12,
}

import _ from 'lodash';
import { useEffect, useState } from 'react';

import RecognitionHub from './RecognitionHub';
import RuntimeHub from './RuntimeHub';

import * as Types from '../Config/Types';
import BundleEditorActions from '../actions/Rec/BundleEditorActions';
import RpaScriptHubActions from '../actions/Rpa/RpaScriptHubActions';

export default function Hubs(authentication: Types.AuthenticationState_TYPE, configuration: Types.ConfigurationState_TYPE) {
    const rpaScriptHubActions = RpaScriptHubActions();

    const bundleEditorActions = BundleEditorActions();

    const [localState] = useState({
        recognitionHub: new RecognitionHub(),
        runtimeHub: new RuntimeHub()
    });

    const token = authentication.token;
    useEffect(() => {
        startHubs(authentication, configuration);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    // Update the reference on the RecognitionHub (this because) we use Redux and we need to refresh the value of the state in the function
    if (localState) {
        localState.recognitionHub.setActions(bundleEditorActions);
        localState.runtimeHub.setActions(rpaScriptHubActions);
    }

    const startHubs = (authentication: Types.AuthenticationState_TYPE, configuration: Types.ConfigurationState_TYPE) => {
        if (_.isNil(authentication.token) || _.isEmpty(authentication.token))
            return;

        if (localState) {
            if (configuration.recognition)
                localState.recognitionHub.start(authentication, configuration);

            if (configuration.runtime)
                localState.runtimeHub.start(authentication, configuration);

            // Here all the others
        }
    };

    const stopHubs = (configuration: Types.ConfigurationState_TYPE) => {
        if (localState) {
            if (configuration.recognition)
                localState.recognitionHub.stop();

            if (configuration.runtime)
                localState.runtimeHub.stop();

            // Here all the others
        }
    };

    return {
        stopHubs
    };
}

import _ from 'lodash';
import { Components } from '@firedesktop/react-base';
import {
    BpmnDiagrams,
    BasicShapeModel,
    ConnectorModel,
    ContextMenuSettingsModel,
    DiagramComponent,
    DiagramContextMenu,
    DiagramModel,
    DiagramTools,
    IDropEventArgs,
    IExportOptions,
    Inject,
    IClickEventArgs,
    ICollectionChangeEventArgs,
    IConnectionChangeEventArgs,
    ISizeChangeEventArgs,
    ITextEditEventArgs,
    NodeConstraints,
    NodeModel,
    PageSettingsModel,
    PrintAndExport,
    ScrollSettingsModel,
    SelectorConstraints,
    SelectorModel,
    UndoRedo,
    ZoomOptions
} from '@syncfusion/ej2-react-diagrams';
import { MenuEventArgs, ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
// @ts-ignore
import { diff } from 'deep-diff';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import PaletteComponent from './PaletteComponent';

/**
 * This function is used to initialize and empty DocumentEditor
 */
export function createInitialState_documentEditor_object() {
    return {
        connectors: [],
        id: -1,
        name: '',
        nodes: [],
        pageSettings: undefined,
        // Paging
        activePage: null,
        totalPages: null
    } as DocumentEditor_Object;
};

export type DocumentEditor_Object = {
    connectors: ConnectorModel[]
    id: number | string
    /**
     * This is just a random number used to force Syncfusion to refresh data
     */
    forceRefresh?: number
    name: string
    nodes: Array<NodeModel>
    pageSettings: PageSettingsModel | undefined
    // Paging
    activePage: number | undefined | null
    totalPages: number | undefined | null
}

export type DroppedNode_Type = {
    diagramNode: NodeModel
    height: number
    width: number
    x: number,
    y: number
}

type Labels = {
    button_alignDocumentEditor_tooltip: string
    button_enableDraw_tooltip: string
    button_enablePanZoom_tooltip: string
    button_zoomIn_tooltip: string
    button_zoomOut_tooltip: string
    button_zoomReset_tooltip: string
    changePage_tooltip: string
    contextMenu: {
        pasteFromClipboard: string
        copyToClipboard: string
        delete: string
    }
    copyToClipboard_node_success: string
    copyToClipboard_node_failed: string
    onDropDisabled: string
    pasteFromClipboard_node_failed: string
}

export type OnDropReturn = {
    addInfo: object | undefined
    toBeAddedConnectors: ConnectorModel[] | undefined
    dropAccepted: boolean
}

export type OnNodeAddedReturn = {
    addInfo: object | undefined
    toBeAddedConnectors: ConnectorModel[] | undefined
}

interface IDocumentEditorProps {
    documentEditorObject: DocumentEditor_Object
    dropEnabled?: boolean // This is not enough to drop, we also need a PageSetting defined in documentEditorObject
    fileName?: string
    history: any
    diagramItemsManagedExternal: boolean
    labels: Labels
    multipleItemSelection?: boolean
    name: string
    onConnectionChange?: (nodeId: string, portId: string, connectorEnd: string, diagramNodes: NodeModel[] | undefined, connectors: ConnectorModel[] | undefined) => string
    /**
     * Used to add custom properties to the node when an item is dropped
     */
    onDropAddInfo?: (node: DroppedNode_Type, nodes: NodeModel[] | undefined, connectors: ConnectorModel[] | undefined) => OnDropReturn
    /**
     * This function is invoked every time a node has been added to the Diagram
     */
    onNodeAdded?: (id: string, addInfo: object | undefined, nodes: NodeModel[] | undefined, connectors: ConnectorModel[] | undefined) => OnNodeAddedReturn | undefined
    onNodeCopyToClipboard?: (nodes: NodeModel[], connectors: ConnectorModel[] | undefined) => string | undefined
    onNodePasteFromClipboard?: (nodeInJsonString: string, mousePosition_x: number, mousePosition_y: number, diagramNodes: NodeModel[] | undefined) =>
        {
            connections: ConnectorModel[]
            nodes: NodeModel[]
        } | undefined
    onNodeRemoved?: (id: string, addInfo: object | undefined, nodes: NodeModel[] | undefined, connectors: ConnectorModel[] | undefined) => void
    onNodeClick?: (id?: string, x?: number, y?: number, customNodeProperties?: object) => void
    onNodeSizeChange?: (id?: string, width?: number, heigth?: number, customNodeProperties?: object) => void
    onPageChange?: (page: number) => void
    onDiagramItem_TextEdit?: (id: string, text: string, oldText: string, type: 'node' | 'connector', addInfo: object | undefined, nodes: NodeModel[] | undefined, connectors: ConnectorModel[] | undefined) => void
    selectedNodesIds?: Array<string>
    showPaletteComponent: boolean
    showContextMenu?: boolean
    toast?: (toastObject: Components.Toaster_Types.Toaster_Prop_Type) => void
}

interface DocumentEditorState {
    // ********************************************************************************************************
    /**
     * These two proerties are used to reduce state update and redraw, look in getDerivedStateFromProps
     */
    documentEditorObject_FromProps_not_modified: DocumentEditor_Object,
    selectedNodesIds?: Array<string>,
    // ********************************************************************************************************
    documentEditorObject: DocumentEditor_Object,
    diagramInstance: React.RefObject<DiagramComponent>,
    fatherInstance: React.RefObject<HTMLDivElement>,
    height: number,
    mousePosition_x: string,
    mousePosition_y: string,
    nodeName: string,
    panOrDraw: boolean,
    scale: number,
    width: number
}

/**
 * Connectors
 *  https://ej2.syncfusion.com/react/demos/?_ga=2.170274467.260288420.1605276357-2016717951.1602861354#/material/diagram/default-functionality
 * 
 * DiagramComponent Api:
 *  https://ej2.syncfusion.com/react/documentation/api/diagram/  
 */
class DocumentEditor extends
    React.Component<IDocumentEditorProps, DocumentEditorState> {

    constructor(props: IDocumentEditorProps) {
        super(props);
        this.state = {
            documentEditorObject_FromProps_not_modified: createInitialState_documentEditor_object(),
            documentEditorObject: createInitialState_documentEditor_object(),
            diagramInstance: React.createRef<DiagramComponent>(),
            fatherInstance: React.createRef<HTMLDivElement>(),
            height: window.innerHeight * .80,
            mousePosition_x: '',
            mousePosition_y: '',
            nodeName: 'partialName',
            panOrDraw: true,
            scale: 0,
            width: window.innerWidth * .40
        };
    }

    // ****************************************************************************************
    // React Methods
    // ****************************************************************************************
    static getDerivedStateFromProps(props: IDocumentEditorProps, state: DocumentEditorState): Partial<DocumentEditorState> | null {
        if (props.documentEditorObject && props.documentEditorObject.nodes && state.fatherInstance.current && state.diagramInstance.current) {
            if (props.diagramItemsManagedExternal) {
                const documentEditorObject = _.cloneDeep(props.documentEditorObject);
                // ****************************************************************************************
                // New page or new documentEditorObject ==> we have to recreate the diagram... 
                // ****************************************************************************************
                if (documentEditorObject.id !== state.documentEditorObject.id || documentEditorObject.activePage !== state.documentEditorObject.activePage ||
                    (documentEditorObject.forceRefresh && documentEditorObject.forceRefresh !== state.documentEditorObject.forceRefresh)) {
                    console.log(`DocumentEditor - Evaluate Properties to update state - Recreate Diagram - nodes: ${state.diagramInstance.current.nodes.length}, propNodes: ${documentEditorObject.nodes.length}, stateNodes: ${state.documentEditorObject.nodes.length}` +
                        `Props forceRefresh: ${documentEditorObject.forceRefresh}, State forceRefresh: ${state.documentEditorObject.forceRefresh}`);

                    // From Props object
                    const connectors = documentEditorObject.connectors ?? [];
                    const nodes = documentEditorObject.nodes;
                    let pageSettings = documentEditorObject.pageSettings;

                    const newWidth = state.fatherInstance.current.offsetWidth;

                    const partialState = DocumentEditor.documentEditorResize(connectors, nodes, pageSettings, nodes, pageSettings, state, newWidth);

                    // ****************************************************************************************
                    // Populate Diagram programmatically and not with properties because it does not work in that way
                    // ****************************************************************************************
                    state.diagramInstance.current.clear();
                    // Add nodes
                    for (let index = 0; index < nodes.length; index++)
                        state.diagramInstance.current.addNode(nodes[index]);

                    // Add connectors
                    for (let index = 0; index < connectors.length; index++)
                        state.diagramInstance.current.addConnector(connectors[index]);

                    // Set pageSettings
                    if (!pageSettings)
                        pageSettings = DocumentEditor.createEmptyPageSetting(state.height, state.width);
                    state.diagramInstance.current.pageSettings = pageSettings;

                    // We use props to store the original values...
                    return { ...partialState, documentEditorObject, documentEditorObject_FromProps_not_modified: _.cloneDeep(props.documentEditorObject), selectedNodesIds: props.selectedNodesIds };
                }
                else {
                    const propNodes = documentEditorObject.nodes.sort(function (a, b) {
                        if (!a.id) return -1;
                        if (!b.id) return 1;
                        if (a.id < b.id) return -1;
                        if (a.id > b.id) return 1;
                        return 0;
                    }) ?? [];
                    const stateNodes = state.documentEditorObject_FromProps_not_modified.nodes.sort(function (a, b) {
                        if (!a.id) return -1;
                        if (!b.id) return 1;
                        if (a.id < b.id) return -1;
                        if (a.id > b.id) return 1;
                        return 0;
                    }) ?? [];

                    // ****************************************************************************************
                    // Property on a node modified outside the diagram
                    // ****************************************************************************************
                    const diff_nodes = diff(propNodes, stateNodes);
                    if (propNodes.length === stateNodes.length && stateNodes.length > 0 && diff_nodes && diff_nodes.length) {
                        console.log(`DocumentEditor - Evaluate Properties to update Single Field values - nodes: ${state.diagramInstance.current.nodes.length}, propNodes: ${propNodes.length}, stateNodes: ${stateNodes.length}`);

                        // Allign all the properties for the nodes
                        for (let index = 0; index < diff_nodes.length; index++) {
                            // Values from diff
                            const element = diff_nodes[index];
                            const nodeIndex = element.path[0];
                            const propertyName_01 = element.path[1] as string;
                            let propertyName_02: string | undefined = undefined;
                            if (element.path.length > 2)
                                propertyName_02 = element.path[2] as string;

                            const propertyValue = element.lhs;

                            // Node id from state
                            const nodeId = stateNodes[nodeIndex].id;

                            // Update values using reference Instance
                            const targetDiagramNode = state.diagramInstance.current.nodes.filter(x => { return x.id === nodeId; })[0];
                            if (targetDiagramNode) {

                                console.log(`propertyName: ${propertyName_01}, propertyValue: ${propertyValue}, state.scale: ${state.scale}`);

                                // Dimension
                                if (propertyName_01 === 'height' || propertyName_01 === 'offsetX' || propertyName_01 === 'offsetY' || propertyName_01 === 'width') {
                                    const propertyValueNumber = _.toNumber(propertyValue);
                                    if (!isNaN(propertyValueNumber))
                                        targetDiagramNode[propertyName_01] = propertyValueNumber / state.scale;
                                }
                                // Style
                                else if (propertyName_01 === 'style' &&
                                    targetDiagramNode.style && propertyName_02 === 'strokeColor') {
                                    targetDiagramNode.style[propertyName_02] = propertyValue;
                                }
                            }
                        }

                        // state.diagramInstance.current.refresh();
                        return { ...state, documentEditorObject_FromProps_not_modified: _.cloneDeep(props.documentEditorObject), selectedNodesIds: props.selectedNodesIds };
                    }

                    // ****************************************************************************************
                    // Selectors
                    // ****************************************************************************************
                    const propsSelectedNodesIds = props.selectedNodesIds && props.selectedNodesIds.length ? props.selectedNodesIds[0] : '';
                    const stateSelectedNodesIds = state.selectedNodesIds && state.selectedNodesIds.length ? state.selectedNodesIds[0] : '';
                    if (propsSelectedNodesIds !== stateSelectedNodesIds) {
                        console.log(`DocumentEditor - Evaluate Properties to update Selected Field, "${propsSelectedNodesIds}" !== "${stateSelectedNodesIds}, nodes: ${state.diagramInstance.current.nodes.length}, propNodes: ${propNodes.length}, stateNodes: ${stateNodes.length}`);

                        const selectedNodesIds = props.selectedNodesIds ?? [];
                        const toBeSelectedNodes = state.diagramInstance.current.nodes.filter((node: any) => { return selectedNodesIds.indexOf(node.id) > -1; });
                        state.diagramInstance.current.select(toBeSelectedNodes || []);

                        // state.diagramInstance.current.refresh();
                        return { ...state, documentEditorObject_FromProps_not_modified: _.cloneDeep(props.documentEditorObject), selectedNodesIds: props.selectedNodesIds };
                    }

                    // ****************************************************************************************
                    // Add Node
                    // ****************************************************************************************
                    if (propNodes.length > stateNodes.length) {
                        let toBeRefresh = false;
                        console.log(`DocumentEditor - Evaluate Properties to Add new Node, ${propNodes.length} > ${stateNodes.length}, nodes: ${state.diagramInstance.current.nodes.length}, propNodes: ${propNodes.length}, stateNodes: ${stateNodes.length}`);

                        for (let index = 0; index < propNodes.length; index++) {
                            const element = propNodes[index];
                            if (stateNodes.filter(x => { return x.id === element.id; }).length === 0) {

                                if (element.id) {
                                    // Re proportion based on screen dimension
                                    if (element.offsetX)
                                        element.offsetX = element.offsetX / state.scale;
                                    if (element.offsetY)
                                        element.offsetY = element.offsetY / state.scale;
                                    if (element.width)
                                        element.width = element.width / state.scale;
                                    if (element.height)
                                        element.height = element.height / state.scale;

                                    if (state.diagramInstance.current.nodes.filter(x => x.id === element.id).length === 0) {
                                        // Check if there's another node with same partial id this is a valid Procedure just for 
                                        // Bundle Editor
                                        try {
                                            const splitted = element.id.split('_');
                                            const nodeId = splitted[splitted.length - 1];
                                            // eslint-disable-next-line no-loop-func
                                            const toBeRemoved = state.diagramInstance.current.nodes.filter(x => x.id && x.id.indexOf(nodeId) > -1);
                                            for (let indexToBeRemoved = 0; indexToBeRemoved < toBeRemoved.length; indexToBeRemoved++) {
                                                const elementToBeR = toBeRemoved[indexToBeRemoved];
                                                console.log(`DocumentEditor - Removing node id: ${elementToBeR.id}`);
                                                state.diagramInstance.current.remove(elementToBeR);
                                            }
                                        } catch (err) {
                                            console.error(`Error when trying to remove node from Diagram: ${err}`);
                                        }

                                        state.diagramInstance.current.addNode(element);
                                        toBeRefresh = true;
                                    }
                                }
                            }
                        }
                        if (toBeRefresh) {
                            console.log(`DocumentEditor - Add new Node TO BE DONE - nodes: ${state.diagramInstance.current.nodes.length}, propNodes: ${propNodes.length}, stateNodes: ${stateNodes.length}`);
                            // state.diagramInstance.current.refresh();
                            return { ...state, documentEditorObject_FromProps_not_modified: _.cloneDeep(props.documentEditorObject) };
                        }
                    }
                }
            }
            else {
                const documentEditorObject = _.cloneDeep(props.documentEditorObject);
                if (documentEditorObject.id !== state.documentEditorObject.id || documentEditorObject.activePage !== state.documentEditorObject.activePage ||
                    (documentEditorObject.forceRefresh && documentEditorObject.forceRefresh !== state.documentEditorObject.forceRefresh)) {
                    console.log('DocumentEditor - Evaluate Properties to update state - Recreate Diagram');

                    // From Props object
                    const connectors = documentEditorObject.connectors ?? [];
                    const nodes = documentEditorObject.nodes;
                    let pageSettings = documentEditorObject.pageSettings;

                    const newWidth = state.fatherInstance.current.offsetWidth;

                    const partialState = DocumentEditor.documentEditorResize(connectors, nodes, pageSettings, nodes, pageSettings, state, newWidth);

                    // ****************************************************************************************
                    // Populate Diagram programmatically and not with properties because it does not work in that way
                    // ****************************************************************************************
                    state.diagramInstance.current.clear();
                    // Add nodes
                    for (let index = 0; index < nodes.length; index++)
                        state.diagramInstance.current.addNode(nodes[index]);

                    // Add connectors
                    for (let index = 0; index < connectors.length; index++)
                        state.diagramInstance.current.addConnector(connectors[index]);

                    // Set pageSettings
                    if (!pageSettings)
                        pageSettings = DocumentEditor.createEmptyPageSetting(state.height, state.width);
                    state.diagramInstance.current.pageSettings = pageSettings;

                    // We use props to store the original values...
                    return { ...partialState, documentEditorObject, documentEditorObject_FromProps_not_modified: _.cloneDeep(props.documentEditorObject), selectedNodesIds: props.selectedNodesIds };
                }
                else {
                    // ****************************************************************************************
                    // Here we just look for node and update only the addInfo
                    // ****************************************************************************************

                    const propNodes = documentEditorObject.nodes.sort(function (a, b) {
                        if (!a.id) return -1;
                        if (!b.id) return 1;
                        if (a.id < b.id) return -1;
                        if (a.id > b.id) return 1;
                        return 0;
                    }) ?? [];
                    const stateNodes = state.documentEditorObject_FromProps_not_modified.nodes.sort(function (a, b) {
                        if (!a.id) return -1;
                        if (!b.id) return 1;
                        if (a.id < b.id) return -1;
                        if (a.id > b.id) return 1;
                        return 0;
                    }) ?? [];

                    // ****************************************************************************************
                    // Property on a node modified outside the diagram
                    // ****************************************************************************************
                    const diff_nodes = diff(propNodes, stateNodes);
                    if (propNodes.length === stateNodes.length && stateNodes.length > 0 && diff_nodes && diff_nodes.length) {
                        for (let index = 0; index < diff_nodes.length; index++) {
                            // Values from diff
                            const element = diff_nodes[index];
                            const nodeIndex = element.path[0];
                            const propertyName_01 = element.path[1] as string;
                            let propertyName_02: string | undefined = undefined;
                            let propertyName_03: string | undefined = undefined;
                            if (element.path.length > 2)
                                propertyName_02 = element.path[2] as string;
                            if (element.path.length > 3)
                                propertyName_03 = element.path[3] as string;

                            const propertyValue = element.lhs;

                            // Node id from state
                            const nodeId = stateNodes[nodeIndex].id;

                            const targetDiagramNode = state.diagramInstance.current.nodes.filter(x => { return x.id === nodeId; })[0];
                            if (targetDiagramNode) {

                                if (propertyName_03 !== undefined)
                                    console.log(`DocumentEditor - propertyName 01: ${propertyName_01}, propertyName 02: ${propertyName_02}, propertyName 03: ${propertyName_03}, propertyValue: ${propertyValue}, state.scale: ${state.scale}`);
                                else if (propertyName_02 !== undefined)
                                    console.log(`DocumentEditor - propertyName 01: ${propertyName_01}, propertyName 01: ${propertyName_02}, propertyValue: ${propertyValue}, state.scale: ${state.scale}`);
                                else
                                    console.log(`DocumentEditor - propertyName 01: ${propertyName_01}, propertyValue: ${propertyValue}, state.scale: ${state.scale}`);

                                // Dimension
                                if (propertyName_01 === 'height' || propertyName_01 === 'offsetX' || propertyName_01 === 'offsetY' || propertyName_01 === 'width') {
                                    const propertyValueNumber = _.toNumber(propertyValue);
                                    if (!isNaN(propertyValueNumber))
                                        targetDiagramNode[propertyName_01] = propertyValueNumber / state.scale;
                                }
                                // Style
                                else if (propertyName_01 === 'style' && targetDiagramNode.style && propertyName_02 !== undefined &&
                                    (propertyName_02 === 'strokeColor' || propertyName_02 === 'fill')) {
                                    targetDiagramNode.style[propertyName_02] = propertyValue;
                                }
                                // Annotations
                                else if (propertyName_01 === 'annotations' &&
                                    targetDiagramNode.annotations && propertyName_02 !== undefined && propertyName_03 !== undefined) {
                                    (targetDiagramNode.annotations[parseInt(propertyName_02, 10)] as any)[propertyName_03] = propertyValue;
                                }
                            }
                        }

                        // state.diagramInstance.current.refresh();
                        return { ...state, documentEditorObject_FromProps_not_modified: _.cloneDeep(props.documentEditorObject), selectedNodesIds: props.selectedNodesIds };
                    }

                    // ****************************************************************************************
                    // Add Node
                    // ****************************************************************************************
                    if (propNodes.length > stateNodes.length) {
                        console.log(`DocumentEditor - Evaluate Properties to Add new Node, ${propNodes.length} > ${stateNodes.length}`);
                        return { ...state, documentEditorObject_FromProps_not_modified: _.cloneDeep(props.documentEditorObject) };
                    }
                }
            }
        }

        return null;
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResize);
        // Set default width and height based on window size
        setTimeout(() => {
            this.setState((state) => {
                if (state.fatherInstance && state.fatherInstance.current) {
                    if (state.diagramInstance.current) {
                        state.diagramInstance.current.width = state.fatherInstance.current.offsetWidth;
                        state.diagramInstance.current.height = window.innerHeight * .80;
                        state.diagramInstance.current.refresh();
                    }

                    return {
                        height: window.innerHeight * .80,
                        width: state.fatherInstance.current.offsetWidth
                    } as DocumentEditorState;
                }
                return state;
            });
        }, 500);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize);
    }

    // ****************************************************************************************
    // Custom Methods
    // **************************************************************************************** 

    static createEmptyPageSetting = (height: number, width: number): PageSettingsModel => {
        return {
            background: {
                align: 'None',
                source: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
                scale: 'None'
            },
            boundaryConstraints: 'Diagram',
            height: height,
            orientation: 'Portrait',
            width: width
        } as PageSettingsModel;
    };

    createToolbar = (documentEditorObject: DocumentEditor_Object) => {
        const maxSize = 5;
        const buttonStyle = {
            fontSize: 20,
            marginLeft: 2
        };

        const imageDimension = this.state.documentEditorObject_FromProps_not_modified.pageSettings
            ? `W: ${this.state.documentEditorObject_FromProps_not_modified.pageSettings.width} H: ${this.state.documentEditorObject_FromProps_not_modified.pageSettings?.height}`
            : 'W: H: ';

        return (
            <ToolbarComponent>
                <div>
                    <div>
                        <TooltipComponent id="details" position='TopCenter' content={this.props.labels.button_alignDocumentEditor_tooltip ?? ''} >
                            <button className='e-control e-btn e-tbar-btn' onClick={this.windowResize} style={buttonStyle}>
                                <i className="fas fa-expand-alt"></i>
                            </button>
                        </TooltipComponent>
                    </div>
                    <div>
                        <TooltipComponent id="details" position='TopCenter' content={this.props.labels.button_zoomIn_tooltip ?? ''} >
                            <button className='e-control e-btn e-tbar-btn' onClick={this.zoomIn} style={buttonStyle}>
                                <i className='fas fa-search-plus'></i>
                            </button>
                        </TooltipComponent>
                    </div>
                    <div>
                        <TooltipComponent id="details" position='TopCenter' content={this.props.labels.button_zoomOut_tooltip ?? ''} >
                            <button className='e-control e-btn e-tbar-btn' onClick={this.zoomOut} style={buttonStyle}>
                                <i className='fas fa-search-minus'></i>
                            </button>
                        </TooltipComponent>
                    </div>
                    <div>
                        <TooltipComponent id="details" position='TopCenter' content={this.props.labels.button_zoomReset_tooltip ?? ''} >
                            <button className='e-control e-btn e-tbar-btn' onClick={this.zoomReset} style={buttonStyle}>
                                <i className="fa-solid fa-magnifying-glass-location"></i>
                            </button>
                        </TooltipComponent>
                    </div>
                    <div>Zoom: {(this.state.diagramInstance.current?.scrollSettings?.currentZoom ?? 0) * 100} %</div>
                    <div>
                        <TooltipComponent id="details" position='TopCenter' content={this.state.panOrDraw ?
                            this.props.labels.button_enableDraw_tooltip :
                            this.props.labels.button_enablePanZoom_tooltip} >
                            <button className='e-control e-btn e-tbar-btn' onClick={() => {
                                this.setState((state) => {
                                    return { ...state, panOrDraw: !state.panOrDraw };
                                });
                            }} style={buttonStyle} >
                                {this.state.panOrDraw ? <i className="fas fa-plus"></i> : <i className="far fa-hand-rock"></i>}
                            </button>
                        </TooltipComponent>
                    </div>
                    <div className='e-separator'> </div>
                    <div>X: {this.state.mousePosition_x} Y: {this.state.mousePosition_y}</div>
                    <div className='e-separator'> </div>
                    <div>{imageDimension}</div>
                    <div className='e-separator'> </div>
                    <div style={{ position: 'absolute', right: 0 }}>
                        {documentEditorObject && documentEditorObject.activePage && documentEditorObject.totalPages && documentEditorObject.totalPages > 1 ?
                            <TooltipComponent id="details" position='TopCenter' content={this.props.labels.changePage_tooltip ?? ''} >
                                <Form.Control
                                    max={documentEditorObject.totalPages}
                                    min={1}
                                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                        if (_.isEmpty((e.target as any).value))
                                            return;
                                        const value = _.toNumber((e.target as any).value);
                                        if (!value)
                                            (e.target as any).value = 1;
                                        else if (documentEditorObject.totalPages && e.key === 'Enter' && value > 0 && value <= documentEditorObject.totalPages)
                                            this.onPageChange(value);
                                    }}
                                    onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                    style={{ maxWidth: 50, height: 28 }}
                                    type="number" />
                            </TooltipComponent>
                            : ''}
                        {documentEditorObject && documentEditorObject.activePage && documentEditorObject.totalPages ?
                            <Components.AppPagination activePage={documentEditorObject.activePage} itemsPerPage={1}
                                onPageChange={this.onPageChange} totalItems={documentEditorObject.totalPages} maxSize={maxSize}
                            /> : ''}
                    </div>
                </div>
            </ToolbarComponent >
        );
    };

    exportDiagramInJson = (): DiagramModel | undefined => {
        if (this.state.diagramInstance.current) {
            const diagramScript = this.state.diagramInstance.current.saveDiagram();
            return JSON.parse(diagramScript) as DiagramModel;
        }

        return;
    };

    exportDiagramInImage = () => {
        if (this.state.diagramInstance.current) {
            const options: IExportOptions = {
                fileName: this.props.fileName ?? 'diagram',
                format: 'SVG',
                pageOrientation: this.state.documentEditorObject.pageSettings?.orientation,
                margin: { left: 10, right: 10, top: 10, bottom: 10 },
                mode: 'Download'
            };
            this.state.diagramInstance.current.exportDiagram(options);
        }
    };

    exportDiagramInImageInBytes = (): string | undefined => {
        if (this.state.diagramInstance.current) {
            const options: IExportOptions = {
                fileName: this.props.fileName ?? 'diagram',
                format: 'PNG', // If SVG this will return SVGElement
                pageOrientation: this.state.documentEditorObject.pageSettings?.orientation,
                margin: { left: 10, right: 10, top: 10, bottom: 10 },
                mode: 'Data'
            };
            return this.state.diagramInstance.current.exportDiagram(options) as string;
        }
        return undefined;
    };

    exportDiagramInSVG = (): SVGAElement | undefined => {
        if (this.state.diagramInstance.current) {
            const options: IExportOptions = {
                fileName: this.props.fileName ?? 'diagram',
                format: 'SVG', // If SVG this will return SVGElement
                pageOrientation: this.state.documentEditorObject.pageSettings?.orientation,
                margin: { left: 10, right: 10, top: 10, bottom: 10 },
                mode: 'Data'
            };
            const svgElement = this.state.diagramInstance.current.exportDiagram(options) as SVGAElement;
            return svgElement;
        }
        return undefined;
    };

    isDropEnabled = (): boolean => {
        if (this.props.dropEnabled && this.state.documentEditorObject.pageSettings)
            return true;
        return false;
    };

    onMouseMoveOnEditor = (event?: React.MouseEvent) => {
        if (event && this.state.fatherInstance.current) {
            // console.log('DocumentEditor: onMouseMoveOnEditor');
            const bound = this.state.fatherInstance.current.getBoundingClientRect();
            this.setState((state) => {
                const mousePosition_x = Math.round((event?.clientX - bound.x) * this.state.scale).toString();
                const mousePosition_y = Math.round((event?.clientY - bound.y) * this.state.scale).toString();
                return { ...state, mousePosition_x, mousePosition_y };
            });
        }
    };

    onPageChange = (page: number): void => {
        if (this.props.onPageChange) {
            // console.log('DocumentEditor: onPageChange');
            this.props.onPageChange(page);
        }
    };

    zoomIn = () => {
        const zoomOptions: ZoomOptions = {
            type: 'ZoomIn',
            zoomFactor: 0.1
        };
        if (this.state.diagramInstance && this.state.diagramInstance.current) {
            this.state.diagramInstance.current.zoomTo(zoomOptions);
            this.setState((state) => {
                return { ...state };
            });
        }
    };

    zoomOut = () => {
        const zoomOptions: ZoomOptions = {
            type: 'ZoomOut',
            zoomFactor: 0.1

        };
        if (this.state.diagramInstance && this.state.diagramInstance.current) {
            this.state.diagramInstance.current.zoomTo(zoomOptions);
            this.setState((state) => {
                return { ...state };
            });
        }
    };

    zoomReset = () => {
        if (this.state.diagramInstance && this.state.diagramInstance.current) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.diagramInstance.current.scrollSettings.currentZoom = 1;
            this.state.diagramInstance.current.refresh();
            this.setState((state) => {
                return { ...state };
            });
        }
    };
    // ****************************************************************************************
    // Diagram Events
    // ****************************************************************************************
    collectionChange = (args?: ICollectionChangeEventArgs) => {
        if (args && args.state === 'Changed') {
            const node = args.element as NodeModel;
            // Check if this is a node
            if (node && node.id && (node as any).actualSize) {
                // console.log('DocumentEditor: collectionChange');
                if (args.type === 'Addition' && this.props.onNodeAdded) {
                    const ret = this.props.onNodeAdded(node.id, node.addInfo, this.state.diagramInstance.current?.nodes, this.state.diagramInstance.current?.connectors);
                    if (ret) {
                        node.addInfo = ret.addInfo;
                        // Add connectors, using timeout to help Syncfusion...
                        setTimeout(() => {
                            if (ret.toBeAddedConnectors && this.state.diagramInstance.current) {
                                for (let index = 0; index < ret.toBeAddedConnectors.length; index++)
                                    this.state.diagramInstance.current.addConnector(ret.toBeAddedConnectors[index]);
                            }
                        }, 100);
                    }
                } else if (args.type === 'Removal' && this.props.onNodeRemoved) {
                    this.props.onNodeRemoved(node.id, node.addInfo, this.state.diagramInstance.current?.nodes, this.state.diagramInstance.current?.connectors);
                }
            } else {
                // It's a connection, not managed for now
            }
        }
    };

    connectionChange = (args?: IConnectionChangeEventArgs) => {
        if (args && args.state === 'Changed') {
            const connector = args.newValue as { nodeId: string; portId: string; };

            // If the connection between node and connector does not have a port ID we ask outside to the invoker
            if (connector && connector.portId === '') {
                if (this.props.onConnectionChange) {
                    // console.log('DocumentEditor: connectionChange');
                    const newPortId = this.props.onConnectionChange(connector.nodeId, connector.portId, args.connectorEnd,
                        this.state.diagramInstance.current?.nodes, this.state.diagramInstance.current?.connectors);

                    if (!_.isNil(newPortId) && !_.isEmpty(newPortId)) {
                        setTimeout(() => {
                            if (this.state.diagramInstance.current) {
                                const arrow = this.state.diagramInstance.current.connectors.filter(x => x.id === args.connector.id)[0];
                                if (arrow.sourceID === connector.nodeId) {
                                    arrow.sourcePortID = newPortId;
                                } else {
                                    arrow.targetPortID = newPortId;
                                }
                            }
                        }, 100);
                    }
                }
            }
        }
    };

    contextMenuClick = (args?: MenuEventArgs) => {
        if (args && this.state.diagramInstance.current) {
            if (args.item.id === 'copyToClipboard') {
                if (this.state.diagramInstance.current.selectedItems &&
                    this.state.diagramInstance.current.selectedItems.nodes && this.state.diagramInstance.current.selectedItems.nodes.length) {
                    // const node = this.state.diagramInstance.current.selectedObject.actualObject as NodeModel;
                    const nodes = this.state.diagramInstance.current.selectedItems.nodes;
                    const messageToShow = nodes.map(x => x.annotations && x.annotations[0] ? x.annotations[0].content : '').toString();
                    if (this.props.onNodeCopyToClipboard) {
                        try {
                            const nodeInString = this.props.onNodeCopyToClipboard(this.state.diagramInstance.current.selectedItems.nodes,
                                this.state.diagramInstance.current.connectors);
                            if (!nodeInString)
                                throw new Error('No valid Node');

                            const labels = this.props.labels;
                            const toast = this.props.toast;
                            const state = this.state;
                            navigator.clipboard.writeText(nodeInString).then(function () {
                                // console.log('Copying to clipboard was successful!');
                                if (toast && state.diagramInstance.current) {
                                    if (toast && state.diagramInstance.current) {
                                        toast({
                                            title: `${messageToShow} ${labels.copyToClipboard_node_success}`,
                                            type: 'Information'
                                        });
                                    }
                                }
                            }, function (err) {
                                console.error('Copying to clipboard FALIED: ', err);
                                throw err;
                            });
                        } catch (err) {
                            if (this.props.toast) {
                                this.props.toast({
                                    title: `${messageToShow} ${this.props.labels.copyToClipboard_node_failed}`,
                                    type: 'Warning'
                                });
                            }
                            console.error('Copying to clipboard NOT SUPPORTED IN BROWSER: ', err);
                        }
                    }
                    else {
                        if (this.props.toast) {
                            this.props.toast({
                                title: `${messageToShow} ${this.props.labels.copyToClipboard_node_failed}`,
                                type: 'Warning'
                            });
                        }
                    }
                }
            }
            else if (args.item.id === 'delete') {
                this.state.diagramInstance.current.cut();
            } else if (args.item.id === 'pasteFromClipboard') {
                if (!this.isDropEnabled() && this.props.toast && this.props.labels.onDropDisabled) {
                    this.props.toast({
                        title: this.props.labels.onDropDisabled,
                        type: 'Warning'
                    });

                    return;
                }

                if (this.props.onNodePasteFromClipboard) {
                    try {
                        const onNodePasteFromClipboard = this.props.onNodePasteFromClipboard;
                        const state = this.state;
                        const mousePosition_x = parseFloat(this.state.mousePosition_x);
                        const mousePosition_y = parseFloat(this.state.mousePosition_y);
                        const toast = this.props.toast;
                        const labels = this.props.labels;

                        navigator.clipboard.readText().then(function (value: string) {
                            const obj = onNodePasteFromClipboard(value, mousePosition_x, mousePosition_y, state.diagramInstance.current?.nodes);
                            if (obj && obj.nodes && state.diagramInstance.current) {
                                const toBeSelectedNodesIds: string[] = [];
                                for (let index = 0; index < obj.nodes.length; index++) {
                                    const node = _.cloneDeep(obj.nodes[index]);

                                    if (node.offsetX)
                                        node.offsetX = node.offsetX / state.scale;
                                    if (node.offsetY)
                                        node.offsetY = node.offsetY / state.scale;
                                    if (node.width)
                                        node.width = node.width / state.scale;
                                    if (node.height)
                                        node.height = node.height / state.scale;

                                    state.diagramInstance.current.addNode(node);
                                    if (node.id)
                                        toBeSelectedNodesIds.push(node.id);
                                }

                                // This to select the pasted nodes
                                setTimeout(() => {
                                    if (state && state.diagramInstance.current) {
                                        // Add connections
                                        if (obj.connections) {
                                            for (let index = 0; index < obj.connections.length; index++)
                                                state.diagramInstance.current.addConnector(obj.connections[index]);
                                        }

                                        // Select the added nodes
                                        const toBeSelectedNodes = state.diagramInstance.current.nodes.filter((node: any) => { return toBeSelectedNodesIds.indexOf(node.id) > -1; });
                                        state.diagramInstance.current.select(toBeSelectedNodes);
                                    }
                                }, 100);
                            }
                            else if (toast)
                                toast({
                                    title: labels.pasteFromClipboard_node_failed,
                                    type: 'Warning'
                                });
                        }, function (err) {
                            console.error('Paste from clipboard FALIED: ', err);
                        });
                    } catch (err) {
                        if (this.props.toast) {
                            this.props.toast({
                                title: this.props.labels.pasteFromClipboard_node_failed,
                                type: 'Warning'
                            });
                        }
                        console.error('Paste from clipboard NOT SUPPORTED IN BROWSER: ', err);
                    }
                }
                else {
                    if (this.props.toast) {
                        this.props.toast({
                            title: this.props.labels.pasteFromClipboard_node_failed,
                            type: 'Warning'
                        });
                    }
                }
            }
        }
    };

    diagramClick = (args?: IClickEventArgs) => {
        if (args && args.element) {
            // console.log('DocumentEditor: diagramClick');
            const nodo = args.element as NodeModel;
            if (nodo && nodo.id && this.props.onNodeClick) {
                const nodeX = Math.round(((nodo.offsetX ?? 0) - (nodo.width ?? 0) / 2) * this.state.scale);
                const nodeY = Math.round(((nodo.offsetY ?? 0) - (nodo.height ?? 0) / 2) * this.state.scale);
                if (nodo.id.indexOf(this.state.nodeName) > -1) {
                    if (this.props.toast) {
                        const message = `X: ${nodeX}, Y: ${nodeY}, Width: ${Math.round((nodo.width ?? 0) * this.state.scale)}, Height: ${Math.round((nodo.height ?? 0) * this.state.scale)}`;
                        try {
                            navigator.clipboard.writeText(message).then(function () {
                                // console.log('Copying to clipboard was successful!');
                            }, function (err) {
                                console.error('Copying to clipboard FALIED: ', err);
                            });
                        } catch (err) { console.error('Copying to clipboard NOT SUPPORTED IN BROWSER: ', err); }

                        this.props.toast({
                            title: message,
                            type: 'Information'
                        });
                    }
                }
                else {
                    this.props.onNodeClick(nodo.id, nodeX, nodeY, nodo.addInfo);
                }
            }
        }
        else if (this.props.onNodeClick)
            this.props.onNodeClick(undefined, undefined, undefined, undefined);
    };

    onDrop = (args?: IDropEventArgs) => {
        if (args) {
            args.cancel = !this.isDropEnabled();
            if (args.cancel && this.props.toast && this.props.labels.onDropDisabled) {
                this.props.toast({
                    title: this.props.labels.onDropDisabled,
                    type: 'Warning'
                });
            }
            else {
                // console.log('DocumentEditor: onDrop');
                const node = args.element as NodeModel;
                // Check if this is a node
                if (node && node.id && (node as any).actualSize && this.props.onDropAddInfo) {
                    // In this case the Diagram is going to manage all items
                    args.cancel = true;

                    const fdNode = {
                        // This has been set in Palette
                        diagramNode: node,
                        height: (node as any).actualSize.height * this.state.scale,
                        width: (node as any).actualSize.width * this.state.scale,
                        x: (args.position.x ?? 0) * this.state.scale,
                        y: (args.position.y ?? 0) * this.state.scale,
                    };

                    const ret = this.props.onDropAddInfo(fdNode, this.state.diagramInstance.current?.nodes, this.state.diagramInstance.current?.connectors);
                    if (ret && ret.dropAccepted) {
                        args.cancel = false;
                        node.addInfo = ret.addInfo;

                        // Add connectors, using timeout to help Syncfusion...
                        setTimeout(() => {
                            if (ret.toBeAddedConnectors && this.state.diagramInstance.current) {
                                for (let index = 0; index < ret.toBeAddedConnectors.length; index++)
                                    this.state.diagramInstance.current.addConnector(ret.toBeAddedConnectors[index]);
                            }
                        }, 100);
                    }
                }
                else {
                    // If it is not a connector we do not add this item to the Diagram
                    const connector = args.element as ConnectorModel;
                    if (!connector)
                        args.cancel = true;
                }
            }
        }
    };

    sizeChange = (args?: ISizeChangeEventArgs) => {
        // Just one node at time
        if (args && args.source && args.source.nodes && args.source.nodes.length === 1 && args.state === 'Completed') {
            const node = args.source.nodes[0] as NodeModel;
            if (node && node.id && this.props.onNodeSizeChange) {
                // console.log('DocumentEditor: sizeChange');
                const nodeWidth = Math.round((node.width ?? 0) * this.state.scale);
                const nodeHeigth = Math.round((node.height ?? 0) * this.state.scale);
                // console.log(`sizeChange on Node: ${node.id}, node.offsetX: ${node.width}, node.height: ${node.height}`);

                this.props.onNodeSizeChange(node.id, nodeWidth, nodeHeigth, node.addInfo);
            }
        }
    };

    textEdit = (args?: ITextEditEventArgs) => {
        if (args && this.props.onDiagramItem_TextEdit && args.element.id) {
            // console.log('DocumentEditor: textEdit');
            const itemType = (args.element as any).actualSize ? 'node' : 'connector';
            this.props.onDiagramItem_TextEdit(args.element.id, args.newValue, args.oldValue, itemType,
                args.element.addInfo, this.state.diagramInstance.current?.nodes, this.state.diagramInstance.current?.connectors);
        }
    };

    windowResize = () => {
        this.setState((state, props) => {
            if (this.state.fatherInstance && this.state.fatherInstance.current) {
                // console.log('DocumentEditor: windowResize');
                const newWidth = this.state.fatherInstance.current.offsetWidth;
                const connectors = this.state.documentEditorObject.connectors ?? []; // this.state.diagramInstance.current ? this.state.diagramInstance.current.connectors : [];
                const nodes = this.state.diagramInstance.current ? this.state.diagramInstance.current.nodes : [];
                const pageSettings = this.state.diagramInstance.current ? this.state.diagramInstance.current.pageSettings : {};
                // Original Values
                const pageSettingsOriginal = this.state.documentEditorObject_FromProps_not_modified ? this.state.documentEditorObject_FromProps_not_modified.pageSettings : {};
                const nodesOriginal = this.state.documentEditorObject_FromProps_not_modified ? this.state.documentEditorObject_FromProps_not_modified.nodes : [];

                return DocumentEditor.documentEditorResize(connectors, nodes, pageSettings, nodesOriginal, pageSettingsOriginal, state, newWidth);
            }
            return null;
        });
    };

    static documentEditorResize = (connectors: ConnectorModel[], nodes: NodeModel[], pageSettings: PageSettingsModel | undefined,
        nodesOriginal: NodeModel[], pageSettingsOriginal: PageSettingsModel | undefined, state: DocumentEditorState, newWidth: number) => {

        // Calcolo le dimensioni di altezza considerando che la larghezza è quella dell'immagine di sfondo se c'è...
        let width = state.width;
        let height = state.height;
        // console.log(`documentEditorResize: State - width: ${width}, height: ${height}, newWidth: ${newWidth}`);

        if (pageSettingsOriginal && pageSettingsOriginal.height && pageSettingsOriginal.width) {
            width = pageSettingsOriginal && pageSettingsOriginal.width ? pageSettingsOriginal.width : 100;
            height = pageSettingsOriginal && pageSettingsOriginal.height ? pageSettingsOriginal.height : 100;

            // console.log(`documentEditorResize: pageSettingsOriginal - width: ${width}, height: ${height}`);
        }

        const scale = width / newWidth;
        const newHeight = height / scale;

        // console.log(`documentEditorResize: width: ${width}, height: ${height}, newWidth: ${newWidth}, scale: ${scale}, nodes.length: ${nodes.length}`);
        if (nodes && nodes.length) {
            for (let index = 0; index < nodes.length; index++) {
                const node = nodes[index];
                // Get the original node
                const nodeOriginal = nodesOriginal.filter(x => x.id === node.id)[0];
                if (nodeOriginal) {
                    // console.log('documentEditorResize: NodeIndex : ' + index + ',  height: ' + node.height + ', width: ' + node.width + ', offsetY: ' + node.offsetY + ', offsetX: ' + node.offsetX, node);
                    node.height = (nodeOriginal.height ?? 0) / scale;
                    node.width = (nodeOriginal.width ?? 0) / scale;
                    node.offsetY = (nodeOriginal.offsetY ?? 0) / scale;
                    node.offsetX = (nodeOriginal.offsetX ?? 0) / scale;
                    // console.log('documentEditorResize: NodeIndex : ' + index + ',  height: ' + node.height + ', width: ' + node.width + ', offsetY: ' + node.offsetY + ', offsetX: ' + node.offsetX);
                }
                else {
                    // I shouldnt be here... but in case
                    // This mean that it was dropped and not added to the nodes list... and for that reason scale is different...
                    if (pageSettings && pageSettings.width) {
                        const appScale = pageSettings.width / newWidth;
                        node.height = (node.height ?? 0) / appScale;
                        node.width = (node.width ?? 0) / appScale;
                        node.offsetY = (node.offsetY ?? 0) / appScale;
                        node.offsetX = (node.offsetX ?? 0) / appScale;
                    }
                }
            }
        }

        // Update setting for the Diagram
        if (pageSettings) {
            pageSettings.height = newHeight;
            pageSettings.width = newWidth;
        }

        // console.log(`documentEditorResize: State - width: ${state.width}, height: ${state.height}`);
        if (state.diagramInstance.current) {
            state.diagramInstance.current.width = newWidth;
            state.diagramInstance.current.height = newHeight;

            // console.log('DocumentEditor: documentEditorResize');
            state.diagramInstance.current.refresh();
        }

        return { height: newHeight, scale: scale, width: newWidth };
    };

    render() {
        // We use the state and not the props to avoid problems with DiagramComponent
        const documentEditorObject = this.state.documentEditorObject;

        const diagramName = this.props.name ?? 'diagram';
        // const nodes = documentEditorObject.nodes ? documentEditorObject.nodes : [];
        // const connectors = documentEditorObject.nodes ? documentEditorObject.connectors : [];

        // ************************************************************************************************************
        // Page and Scroll settings
        // ************************************************************************************************************
        // let pageSettings = this.state.documentEditorObject.pageSettings;
        // if (!pageSettings)
        //     pageSettings = DocumentEditor.createEmptyPageSetting(this.state.height, this.state.width);

        const scrollSettings: ScrollSettingsModel = {
            canAutoScroll: true,
            horizontalOffset: 0,
            verticalOffset: 0,
            scrollLimit: 'Diagram',
            minZoom: .3
        };
        // ************************************************************************************************************
        // Pan Zoom and Draw Setttings
        // ************************************************************************************************************
        const selectionType = this.props.multipleItemSelection ? DiagramTools.MultipleSelect | DiagramTools.SingleSelect : DiagramTools.SingleSelect;
        const tool: DiagramTools = this.state.panOrDraw ? DiagramTools.ZoomPan | selectionType : DiagramTools.ContinuousDraw | selectionType;
        const drawingshape: BasicShapeModel = {
            cornerRadius: 0,
            shape: 'Rectangle',
            type: 'Basic'
        };
        const drawingObject: NodeModel = {
            id: this.state.nodeName,  // Diagram will add chars at the end of the ID
            annotations: [{ content: 'Sample Node' }],
            constraints: NodeConstraints.Default,
            shape: drawingshape,
            style: {
                fill: '#6BA5D7',
                opacity: 0.2,
                strokeColor: 'blue',
                strokeWidth: 1
            }
        };

        // console.log('Nodes:', nodes, 'Connectors:', connectors);

        const contextMenuSettings: ContextMenuSettingsModel = {
            show: this.props.showContextMenu ? this.props.showContextMenu : false,
            showCustomMenuOnly: true,
            items: [
                {
                    iconCss: 'far fa-clone',
                    id: 'copyToClipboard',
                    text: this.props.labels.contextMenu.copyToClipboard
                },
                {
                    iconCss: 'fas fa-paste',
                    id: 'pasteFromClipboard',
                    text: this.props.labels.contextMenu.pasteFromClipboard
                },
                {
                    target: '.e-node',
                    iconCss: 'far fa-trash-alt',
                    id: 'delete',
                    text: this.props.labels.contextMenu.delete
                }]
        };

        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} className='text-center' style={{ fontWeight: 600 }}>
                        {this.props.fileName ?? '---'}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {this.createToolbar(documentEditorObject)}
                    </Col>
                </Row>
                {this.props.showPaletteComponent ? <Row>
                    <Col>
                        <PaletteComponent />
                    </Col>
                </Row> : ''}
                <Row>
                    <Col xs={12}>
                        <div id='diagramFather' style={{ width: '100%' }} ref={this.state.fatherInstance} onMouseMoveCapture={this.onMouseMoveOnEditor}>
                            <DiagramComponent
                                backgroundColor='white'
                                click={this.diagramClick}
                                connectionChange={this.connectionChange}
                                collectionChange={this.collectionChange}
                                contextMenuClick={this.contextMenuClick}
                                contextMenuSettings={contextMenuSettings}
                                drawingObject={drawingObject}
                                drop={this.onDrop}
                                tool={tool}
                                id={diagramName}
                                name={diagramName}
                                ref={this.state.diagramInstance}
                                selectedItems={{
                                    constraints: SelectorConstraints.All & ~SelectorConstraints.ToolTip
                                } as SelectorModel}
                                scrollSettings={scrollSettings}
                                sizeChange={this.sizeChange}
                                // connectors={connectors}
                                // height={this.state.height}
                                // nodes={nodes}
                                // pageSettings={pageSettings}
                                // width={this.state.width}
                                textEdit={this.textEdit}
                            >
                                <Inject services={[BpmnDiagrams, UndoRedo, DiagramContextMenu, PrintAndExport]} />
                            </DiagramComponent>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {this.createToolbar(documentEditorObject)}
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}

export default DocumentEditor;
